import {
  SUBMIT_REGISTER_ERROR,
  SUBMIT_REGISTER_REQUEST,
  SUBMIT_REGISTER_SUCCESS,
  SUBMIT_SOCIAL_LOGIN_REQUEST,
  SUBMIT_SOCIAL_LOGIN_SUCCESS,
  SUBMIT_SOCIAL_LOGIN_ERROR,
  SUBMIT_LOGIN_ERROR,
  SUBMIT_LOGIN_REQUEST,
  SUBMIT_LOGIN_SUCCESS,
  SUBMIT_OTP_ERROR,
  SUBMIT_OTP_REQUEST,
  SUBMIT_OTP_SUCCESS,
  USER_DEFAULT_STATE,
  GET_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_SUCCESS,
  UPDATE_USER_INFO_REQUEST,
  UPDATE_COVER_PHOTO_SUCCESS,
  UPDATE_COVER_PHOTO_REQUEST,
  UPDATE_COVER_PHOTO_ERROR,
  GET_COVER_PHOTO_SUCCESS,
  GET_COVER_PHOTO_REQUEST,
  GET_COVER_PHOTO_ERROR,
  GET_FRIENDS_ERROR,
  GET_FRIENDS_REQUEST,
  GET_FRIENDS_SUCCESS,
  GET_IMAGES_ERROR,
  GET_IMAGES_REQUEST,
  GET_IMAGES_SUCCESS,
  GET_VIDEOS_ERROR,
  GET_VIDEOS_REQUEST,
  GET_VIDEOS_SUCCESS,
  CLEAR_USER_STATE,
  GET_USER_NOTIFICATION_SUCCESS,
} from "../actions/user";

const initialState = {
  videos: [],
  images: [],
  friends: [],
};

const userReducer = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SUBMIT_REGISTER_REQUEST:
    case SUBMIT_LOGIN_REQUEST:
    case SUBMIT_LOGIN_REQUEST:
    case SUBMIT_OTP_REQUEST:
    case UPDATE_COVER_PHOTO_REQUEST:
    case GET_COVER_PHOTO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case SUBMIT_REGISTER_SUCCESS:
    case SUBMIT_SOCIAL_LOGIN_SUCCESS:
    case SUBMIT_LOGIN_SUCCESS:
    case SUBMIT_OTP_SUCCESS:
    case UPDATE_COVER_PHOTO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccess: true,
      };
    case SUBMIT_REGISTER_ERROR:
    case SUBMIT_SOCIAL_LOGIN_ERROR:
    case SUBMIT_LOGIN_ERROR:
    case SUBMIT_OTP_ERROR:
    case UPDATE_COVER_PHOTO_ERROR:
    case GET_COVER_PHOTO_ERROR:
      return {
        ...state,
        isLoading: false,
        err: action.payload,
      };
    case USER_DEFAULT_STATE:
      return {
        isLoading: false,
      };
    case GET_USER_INFO_SUCCESS:
    case UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case UPDATE_USER_INFO_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case GET_COVER_PHOTO_SUCCESS:
      return {
        ...state,
        coverPhoto: action.payload,
      };
    case GET_USER_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };
    case GET_VIDEOS_SUCCESS:
      return {
        ...state,
        videos: action.payload.data,
        totalVideos: action.payload.total,
      };
    case GET_IMAGES_SUCCESS:
      return {
        ...state,
        images: action.payload.data,
        totalImages: action.payload.total,
      };
    case GET_FRIENDS_SUCCESS:
      return {
        ...state,
        friends: action.payload.data,
        totalFriends: action.payload.total,
      };
    case CLEAR_USER_STATE:
      return initialState;
    default:
      return state;
  }
};
export default userReducer;
