import axios from 'axios';
import { GET_POST_ERROR } from "./post";
import { updateToaster } from "./toaster";
import Swal from "sweetalert2";
export const SUBMIT_REGISTER_REQUEST = "SUBMIT_REGISTER_REQUEST";
export const SUBMIT_REGISTER_SUCCESS = "SUBMIT_REGISTER_SUCCESS";
export const SUBMIT_REGISTER_ERROR = "SUBMIT_REGISTER_ERROR";
export const SUBMIT_LOGIN_REQUEST = "SUBMIT_LOGIN_REQUEST";
export const SUBMIT_LOGIN_SUCCESS = "SUBMIT_LOGIN_SUCCESS";
export const SUBMIT_LOGIN_ERROR = "SUBMIT_LOGIN_ERROR";
export const SUBMIT_SOCIAL_LOGIN_REQUEST = "SUBMIT_SOCIAL_LOGIN_REQUEST";
export const SUBMIT_SOCIAL_LOGIN_SUCCESS = "SUBMIT_SOCIAL_LOGIN_SUCCESS";
export const SUBMIT_SOCIAL_LOGIN_ERROR = "SUBMIT_SOCIAL_LOGIN_ERROR";
export const SUBMIT_OTP_REQUEST = "SUBMIT_OTP_REQUEST";
export const SUBMIT_OTP_SUCCESS = "SUBMIT_OTP_SUCCESS";
export const SUBMIT_OTP_ERROR = "SUBMIT_OTP_ERROR";
export const USER_DEFAULT_STATE = "USER_DEFAULT_STATE";
export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_ERROR = "GET_USER_INFO_ERROR";
export const GET_USER_NOTIFICATION_REQUEST = "GET_USER_NOTIFICATION_REQUEST";
export const GET_USER_NOTIFICATION_SUCCESS = "GET_USER_NOTIFICATION_SUCCESS";
export const GET_USER_NOTIFICATION_ERROR = "GET_USER_NOTIFICATION_ERROR";
export const UPDATE_USER_INFO_REQUEST = "UPDATE_USER_INFO_REQUEST";
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_INFO_ERROR = "UPDATE_USER_INFO_ERROR";
export const UPDATE_COVER_PHOTO_SUCCESS = "UPDATE_COVER_PHOTO_SUCCESS";
export const UPDATE_COVER_PHOTO_REQUEST = "UPDATE_COVER_PHOTO_REQUEST";
export const UPDATE_COVER_PHOTO_ERROR = "UPDATE_COVER_PHOTO_ERROR";
export const GET_COVER_PHOTO_SUCCESS = "GET_COVER_PHOTO_SUCCESS";
export const GET_COVER_PHOTO_REQUEST = "GET_COVER_PHOTO_REQUEST";
export const GET_COVER_PHOTO_ERROR = "GET_COVER_PHOTO_ERROR";
export const GET_IMAGES_REQUEST = "GET_IMAGES_REQUEST";
export const GET_IMAGES_SUCCESS = "GET_IMAGES_SUCCESS";
export const GET_IMAGES_ERROR = "GET_IMAGES_ERROR";
export const GET_VIDEOS_REQUEST = "GET_VIDEOS_REQUEST";
export const GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS";
export const GET_VIDEOS_ERROR = "GET_VIDEOS_ERROR";
export const GET_FRIENDS_REQUEST = "GET_FRIENDS_REQUEST";
export const GET_FRIENDS_SUCCESS = "GET_FRIENDS_SUCCESS";
export const GET_FRIENDS_ERROR = "GET_FRIENDS_ERROR";
export const USER_LOGOUT = "USER_LOGOUT";
export const CLEAR_USER_STATE = "CLEAR_USER_STATE";
export const GET_PATREON_DATA= "GET_PATREON_DATA";
export const GET_PATREON_DATA_SUCCESS= " GET_PATREON_DATA_SUCCESS";
export const GET_PATREON_DATA_ERROR = "GET_PATREON_DATA_ERROR";

export const submitRegister =
  (data: any) => async (dispatch: any, getState: any) => {
    const avatar = getState().avatar.saved;
    if (!avatar) {
      const toaster = {
        status: "error",
        message: "Please create avatar...",
        show: true,
      };
      return dispatch(updateToaster(toaster));
    }
    await dispatch({ type: SUBMIT_REGISTER_REQUEST, data });
    try {
      const formData = new FormData();
      formData.append("avatar", avatar);
      if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        formData.append("email", data.email);
        //formData.append("phone", Math.random().toString());
      } else {
        formData.append("phone", data.email);
      }
      formData.append("password", data.password);
      formData.append("f_name", data.f_name);
      formData.append("l_name", data.l_name);
      const response: any = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/register`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      localStorage.setItem("access_token", response.data.data.access_token);
      localStorage.setItem("refresh_token", response.data.data.refresh_token);
      localStorage.setItem("user_id", response.data.data.profile_id);
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Welcome!", "", "success");
      return dispatch({
        type: SUBMIT_REGISTER_SUCCESS,
        payload: response.data.data,
      });
    } catch (err: any) {
      let message = err.message;
      if (err.response && err.response.data) {
        message = err.response.data.message;
        if (!message) message = err.response.data;
      }
      const toaster = { status: "error", message: message, show: true };
      await dispatch(updateToaster(toaster));
      return dispatch({ type: SUBMIT_REGISTER_ERROR, payload: err });
    }
  };
export const submitSocialLogin =
  (data: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: SUBMIT_SOCIAL_LOGIN_REQUEST, data });
    try {
      const formData = new FormData();
      formData.append("email", data.email);
      formData.append("phone", data.phone);
      formData.append("f_name", data.f_name);
      formData.append("l_name", data.l_name);
      formData.append("profile_pic", data.profile_pic);
      formData.append("token", data.token);
      formData.append("auth", data.type);
      const response: any = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/auth/social`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      localStorage.setItem("access_token", response.data.data.access_token);
      localStorage.setItem("refresh_token", response.data.data.refresh_token);
      localStorage.setItem("user_id", response.data.data.profile_id);
      return dispatch({
        type: SUBMIT_SOCIAL_LOGIN_SUCCESS,
        payload: response.data.data,
      });
    } catch (err: any) {
      let message = err.message;
      if (err.response && err.response.data) {
        message = err.response.data.message;
        if (!message) message = err.response.data;
      }
      const toaster = { status: "error", message: message, show: true };
      await dispatch(updateToaster(toaster));
      return dispatch({ type: SUBMIT_SOCIAL_LOGIN_ERROR, payload: err });
    }
  };

export const submitOtp = (data: any) => async (dispatch: any) => {
  await dispatch({ type: SUBMIT_OTP_REQUEST, data });
  try {
    if (data.otp === "3343") {
      return dispatch({
        type: SUBMIT_OTP_SUCCESS,
        paload: {},
      });
    } else {
      const toaster = {
        status: "error",
        message: "incorrect otp.",
        show: true,
      };
      await dispatch(updateToaster(toaster));
      return dispatch({
        type: SUBMIT_OTP_ERROR,
        payload: new Error("incorrect otp"),
      });
    }
  } catch (err: any) {
    const toaster = {
      status: "error",
      message: "email or password is incorrect.",
      show: true,
    };
    await dispatch(updateToaster(toaster));
    return dispatch({ type: SUBMIT_OTP_ERROR, payload: err });
  }
};

export const submitLogin = (data: any) => async (dispatch: any) => {
  await dispatch({ type: SUBMIT_LOGIN_REQUEST, data });
  try {
    const formData: any = { password: data.password };
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      formData.email = data.email;
    } else {
      formData.phone = data.email;
    }
    if(data.recaptchaKey) {
      formData.recaptchaKey = data.recaptchaKey
    }
    const response: any = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/auth`,
      formData
    );

    localStorage.setItem("access_token", response.data.data.access_token);
    localStorage.setItem("refresh_token", response.data.data.refresh_token);
    localStorage.setItem("user_id", response.data.data.profile_id);
    const toaster = {
      status: "Success",
      message: "Successfully logged in",
      show: true,
    };
    await dispatch(updateToaster(toaster));
    return dispatch({
      type: SUBMIT_LOGIN_SUCCESS,
      payload: response.data.data,
    });
  } catch (err: any) {
    let message = "email or password is incorrect.";
    if (err.response && err.response.data) {
      message = err.response.data.message;
    }
    const toaster = { status: "error", message: message, show: true };
    await dispatch(updateToaster(toaster));
    return dispatch({ type: SUBMIT_LOGIN_ERROR, payload: err });
  }
};
export const submitLoginSocial = (data: any) => async (dispatch: any) => {
  await dispatch({ type: SUBMIT_LOGIN_REQUEST, data });
  try {
    const formData: any = { password: data.password };
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      formData.email = data.email;
    } else {
      formData.phone = data.email;
    }
    const response: any = await axios.post(
      `${process.env.REACT_APP_API_URL}/user/auth`,
      formData
    );

    localStorage.setItem("access_token", response.data.data.access_token);
    localStorage.setItem("refresh_token", response.data.data.refresh_token);
    // localStorage.setItem("user_id", response.data.data.profile_id);
    localStorage.setItem("user_id", response.data.data);
    localStorage.setItem("id", response.data.data._id);
    const toaster = {
      status: "Success",
      message: "Successfully logged in",
      show: true,
    };
    await dispatch(updateToaster(toaster));
    return dispatch({
      type: SUBMIT_LOGIN_SUCCESS,
      payload: response.data.data,
    });
  } catch (err: any) {
    let message = "email or password is incorrect.";
    if (err.response && err.response.data) {
      message = err.response.data.message;
    }
    const toaster = { status: "error", message: message, show: true };
    await dispatch(updateToaster(toaster));
    return dispatch({ type: SUBMIT_LOGIN_ERROR, payload: err });
  }
};

/*
 *
 * This function used to get the loggedin user info
 *
 */
export const getUserInfo =
  (profileId = "") =>
  async (dispatch: any) => {
    await dispatch({ type: GET_USER_INFO_REQUEST });
    try {
      const id = profileId ? profileId : localStorage.getItem("user_id");
      let url = `${process.env.REACT_APP_API_URL}/profile/${id}`;
      const response: any = await axios.get(url);
      if (
        response.data.data &&
        response.data.data.email &&
        !response.data.data.email.value
      )
        delete response.data.data.email;
      return dispatch({
        type: GET_USER_INFO_SUCCESS,
        payload: response?.data?.data,
      });
    } catch (err: any) {
      return dispatch({ type: GET_USER_INFO_ERROR, payload: err });
    }
  };
export const getAllNotification =
  (profileId = "") =>
  async (dispatch: any) => {
    await dispatch({ type: GET_USER_NOTIFICATION_REQUEST });
    try {
      // const id = profileId ? profileId : localStorage.getItem('user_id')
      let url = `${process.env.REACT_APP_API_URL}/notification/list`;
      const response: any = await axios.get(url);
      if (response.message == "Invalid Token") {
        localStorage.clear();
        window.location.reload();
      }
      if (
        response.data.data &&
        response.data.data.email &&
        !response.data.data.email.value
      )
        delete response.data.data.email;
      return dispatch({
        type: GET_USER_NOTIFICATION_SUCCESS,
        payload: response.data.data.notifications,
      });
    } catch (err: any) {
      return dispatch({ type: GET_USER_NOTIFICATION_ERROR, payload: err });
    }
  };

/*
 *
 * This function used to update the loggedin user info
 *
 */
export const updateUserInfo = (data: any) => async (dispatch: any) => {
  await dispatch({ type: UPDATE_USER_INFO_REQUEST, payload: data });
  try {
    let modData = { ...data };
    modData.username = modData.f_name + " " + modData.l_name;
    // if (modData.email) delete modData.email;
    // if (modData.username) delete modData.username;
    // console.log(modData);
    const response: any = await axios.put(
      `${process.env.REACT_APP_API_URL}/profile/edit`,
      modData
    );
    // if (
    //   response.data.data &&
    //   response.data.data.email &&
    //   !response.data.data.email.value
    // )
    //   delete response.data.data.email;
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire("Updated!", "", "success");
    return dispatch({
      type: UPDATE_USER_INFO_SUCCESS,
      payload: response.data.data,
    });
  } catch (err: any) {
    return dispatch({ type: UPDATE_USER_INFO_ERROR, payload: err });
  }
};

/*
 *
 * This function used to update the background image
 *
 */
export const updateMedia =
  (coverphoto: any, type: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: UPDATE_COVER_PHOTO_REQUEST });
    try {
      const formData = new FormData();
      formData.append("file", coverphoto);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let url;
      if (type === "cover") {
        url = `${process.env.REACT_APP_API_URL}/profile/upload/cover`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/profile/upload/dp`;
        formData.append("type", type === "avatar" ? "Avatar" : "Profile");
      }
      const response: any = await axios.put(url, formData, config);
      const userid = getState().user.data ? getState().user.data._id : "";
      if (userid) dispatch(getUserInfo());
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Looks great, Updated!", "", "success");
      return dispatch({
        type: UPDATE_COVER_PHOTO_SUCCESS,
        payload: response.data,
      });
    } catch (err: any) {
      return dispatch({ type: UPDATE_COVER_PHOTO_ERROR, payload: err });
    }
  };

/*
 *
 * This function used to get the background image
 *
 */
export const getCoverPhoto = (userid: any) => async (dispatch: any) => {
  await dispatch({ type: GET_COVER_PHOTO_REQUEST });
  try {
    const response: any = await axios.get(
      `${process.env.REACT_APP_API_URL}/profile/cover/${userid}`
    );
    return dispatch({
      type: GET_COVER_PHOTO_SUCCESS,
      payload: response.data,
    });
  } catch (err: any) {
    return dispatch({ type: GET_COVER_PHOTO_ERROR, payload: err });
  }
};

/*
 *
 * This function is used to get all photos
 *
 */
export const getAllImages =
  (id: string, pageNumber = 1) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
console.log("stateee-->",state)

    await dispatch({ type: GET_IMAGES_REQUEST });
    try {
      const response: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/profile/${id}/image?pageNumber=${pageNumber}&pageSize=15`
      );
      if (pageNumber > 1)
        response.data.data = state.user.images.concat(response.data.data);
      return dispatch({
        type: GET_IMAGES_SUCCESS,
        payload: {
          data: response.data.data?.data,
          total: response.data.data?.total,
        },
      });
    } catch (err: any) {
      return dispatch({ type: GET_IMAGES_ERROR, payload: err });
    }
  };

/*
 *
 * This function is used to get all photos
 *
 */
export const getAllVideos =
  (id: string, pageNumber = 1) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    await dispatch({ type: GET_VIDEOS_REQUEST });
    try {
      const response: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/profile/${id}/video?pageNumber=${pageNumber}&pageSize=15`
      );
      if (pageNumber > 1)
        response.data.data = state.user.videos.concat(response.data.data);
      return dispatch({
        type: GET_VIDEOS_SUCCESS,
        payload: {
          data: response.data.data?.data,
          total: response.data.data?.total,
        },
      });
    } catch (err: any) {
      return dispatch({ type: GET_POST_ERROR, payload: err });
    }
  };

  /*
 *
 * This function is used to get all photos
 *
 */
export const getPatreonData =(page_id: string) =>
async (dispatch: any, getState: any) => {
  const state = getState();
  await dispatch({ type: GET_PATREON_DATA });
  try {
    const response: any = await axios.get(`${process.env.REACT_APP_API_URL}/patreon/get-post/${page_id}?offset=0`);
    response.data.data = state.user.images.concat(response.data.data);
    return dispatch({
      type:  GET_PATREON_DATA_SUCCESS,
      payload: {
        data: response.data.data?.data,
        total: response.data.data?.total,
      },
    });
  } catch (err: any) {
    return dispatch({ type: GET_PATREON_DATA_ERROR, payload: err });
  }
};

/*
 *
 * This function is used to get all photos
 *
 */
export const getAllFriends =
  (id?: string, pageNumber = 1) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    let modId = id || localStorage.getItem("user_id");
    await dispatch({ type: GET_FRIENDS_REQUEST });
    try {
      const response: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/profile/${modId}/friends?pageNumber=${pageNumber}&pageSize=15`
      );
      if (pageNumber > 1)
        response.data.data = state.user.friends.concat(response.data.data);
      return dispatch({
        type: GET_FRIENDS_SUCCESS,
        payload: {
          data: response.data.data?.data,
          total: response.data.data?.total,
        },
      });
    } catch (err: any) {
      return dispatch({ type: GET_FRIENDS_ERROR, payload: err });
    }
  };
