import React from "react";
import ReactDOM from "react-dom";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.scss";
import "./styles/weather-icon.scss";
import "./styles/style.scss";
import "./styles/color.scss";
import "./styles/responsive.scss";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Axios from "axios";

// For GET requests
Axios.interceptors.request.use(
  (req: any) => {
    if (
      !req.url?.includes("user/auth") &&
      !req.url?.includes("register") &&
      !req.url?.includes("user/token") &&
      !req.url?.includes("userinfo")
    ) {
      const accessToken = localStorage.getItem("access_token");
      req.headers["Authorization"] = "Bearer " + accessToken;
      req.headers["Cache-Control"] = "no-cache";
    }
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// For POST requests
Axios.interceptors.response.use(
  (res) => {
    // Add configurations here
    if (res.status === 201) {
      console.log("Posted Successfully");
    }
    return res;
  },
  (err) => {
    if (
      err.response?.status === 401 ||
      err.response.data.message === "401 Unauthorized"
    ) {
    }
    return Promise.reject(err);
  }
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
