import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { configureStore } from "./store";
import PrivateRoute from "./components/privateRoute/privateRoute";
import { library } from "@fortawesome/fontawesome-svg-core";
import "react-multi-carousel/lib/styles.css";
import ForumPosts from "./components/ForumPosts";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";
import WebSocketService from "./services/WebSocketService";

library.add(faUserClock);

// prepare store
const history = createBrowserHistory();
const store = configureStore();

const Login = lazy(() => import("./pages/login"));
const Register = lazy(() => import("./pages/register"));
const ForgotPassword = lazy(() => import("./pages/forgotPassword"));
const NotFound = lazy(() => import("./pages/notFound"));
const Landing = lazy(() => import("./pages/landing"));
const Otp = lazy(() => import("./pages/otp"));
const About = lazy(() => import("./pages/epicPages/about"));
const friend = lazy(() => import("./pages/epicPages/friends"));
const timeline = lazy(() => import("./pages/epicPages/timeline"));
const trainingCenter = lazy(() => import("./pages/epicPages/training-center"));  // training-center - Interviewing users
const Videos = lazy(() => import("./pages/epicPages/video"));
const photo = lazy(() => import("./pages/epicPages/photo"));
const settings = lazy(() => import("./pages/epicPages/settings"));
const AvatarWebview = lazy(() => import("./pages/webview/avatar"));
const ForumLight = lazy(() => import("./pages/ForumLight"));
const ForumCategory = lazy(() => import("./pages/ForumCategory"));
const ForumFaq = lazy(() => import("./pages/ForumFaq"));
const Faq = lazy(() => import("./pages/Faq"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const SupportHelp = lazy(() => import("./pages/SupportHelp"));
const PostView = lazy(() => import("./pages/PostView"));
const Favorite = lazy(() => import("./pages/Favorite"));
const FavoriteList = lazy(() => import("./pages/FavoriteList"));
const Patreon = lazy(() => import("./pages/Patreon"));
const PatreonList = lazy(() => import("./pages/PatreonList"));
const Career = lazy(() => import("./pages/Career/Career"));
const Events = lazy(() => import("./pages/Events"));
const Calendars = lazy(() => import("./pages/Calendars"));
const Followers = lazy(() => import("./pages/Followers"));
const JobPost = lazy(() => import("./pages/JobPost"));
const JobDetails = lazy(() => import("./pages/JobDetails"));
const Invitations = lazy(() => import("./pages/Invitations"));
const ForumCareerLight = lazy(() => import("./pages/ForumCareerLight"));
const CreatePage = lazy(() => import("./pages/CreatePage"));
const CreatePatreonPage = lazy(() => import("./pages/CreatePatreonPage"));
const CreateEvent = lazy(() => import("./pages/CreateEvent"));
const Chats = lazy(() => import("./pages/Chat"));

const App = () => {

return (
  <Provider store={store}>
    <Router>
      <WebSocketService />
      <Suspense
        fallback={
           <div className="wavy-wraper">
            <div className="wavy">
              <span>E</span>
              <span>p</span>
              <span>i</span>
              <span>c</span>
              <span></span>
              <span>M</span>
              <span>u</span>
              <span>c</span>
              <span>h</span>
            </div>
          </div>
        }
      >
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <PrivateRoute exact path="/otp" component={Otp} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <PrivateRoute exact path="/landing" component={Landing} />
          <PrivateRoute exact path="/about/:id" component={About} />
          <PrivateRoute exact path="/friend/:id" component={friend} />
          <PrivateRoute exact path="/timeline/:id" component={timeline} />
          <PrivateRoute exact path="/training-center" component={trainingCenter} />
          <PrivateRoute exact path="/videos/:id" component={Videos} />
          <PrivateRoute exact path="/photos/:id" component={photo} />
          <PrivateRoute path="/settings/:id" component={settings} />
          <PrivateRoute path="/forum" component={ForumLight} />
          <PrivateRoute path="/forum-category/:id" component={ForumCategory} />
          <PrivateRoute path="/forum-posts/:id" component={ForumPosts} />
          <PrivateRoute path="/forum-faq" component={ForumFaq} />
          <PrivateRoute path="/faq" component={Faq} />
          <PrivateRoute path="/favorite/:id" component={Favorite} />
          <PrivateRoute path="/favorite-list/:id" component={FavoriteList} />
          <PrivateRoute path="/patreon/:id" component={Patreon} />
          <PrivateRoute path="/patreon-list/:id" component={PatreonList} />
          <PrivateRoute path="/Careers/:id" component={Career} />
          
          <PrivateRoute
            path="/forum-careerlight"
            component={ForumCareerLight}
          />
          <PrivateRoute path="/privacy-policy" component={PrivacyPolicy} />
          <PrivateRoute path="/support-help" component={SupportHelp} />
          <PrivateRoute path="/post-view/:id" component={PostView} />
          <Route exact path="/webview/avatar" component={AvatarWebview} />
          <Route exact path="/events/:id" component={Events} />
          <Route exact path="/job-post/:id" component={JobPost} />
          <Route exact path="/job-details/:id" component={JobDetails} />
          <Route exact path="/followers/:id" component={Followers} />
          <Route exact path="/invitations/:id" component={Invitations} />
          <Route exact path="/calendars/:id" component={Calendars} />
          <Route exact path="/createpage" component={CreatePage} />
          <Route exact path="/createpatreonpage" component={CreatePatreonPage} />
          <Route exact path="/createEvent/:id" component={CreateEvent} />
          <Route exact path="/editEvent/:id" component={CreateEvent} />
          <Route exact path="/chats" component={Chats} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  </Provider>
);
}

export default App;
