import { connect } from "react-redux";
import ForumPosts from "./ForumPosts";

const mapStateToProps = (state: any) => {
  return { userInfo: state.user.data };
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ForumPosts);
