import { connect } from "react-redux";
import ForumSlider from "./ForumSlider";

const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ForumSlider);
