import React, { useState, useEffect } from "react";
import "./ForumDetails.scss";
import PropTypes from "./types";
import { Link } from "react-router-dom";
import axios from "axios";
import { Col, Row, Modal, Form } from "react-bootstrap";
import { Formik, Field, useFormikContext } from "formik";
import Swal from "sweetalert2";
import InfiniteScroll from "react-infinite-scroll-component";

const ForumDetails: React.FC<PropTypes> = (props) => {
  // @ts-ignore

  // @ts-ignore
  console.log('props', props?.match?.params?.id)
  //states
  const [forums, setForums] = useState<any[]>([]);
  const [replyObject, setReplyObject] = useState({ forumId: 0, reply: "" });
  const [showAddReplyDialog, setShowAddReplyDialog] = useState(false);
  const [showMoreRepliesDialog, setShowMoreRepliesDialog] = useState(false);
  const [replies, setReplies] = useState({
    reply_count: 0,
    replies: [],
    forum_id: "",
  });
  const [hasMore, setHasMore] = useState(true);
  //other hooks
  //handlers

  const fetchMoreData = async () => {
    // console.log('you')

    if (replies.replies.length === replies.reply_count) {
      setHasMore(false);
      
      return;
    }
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/forum/get-reply/${replies.forum_id}?offset=${replies.replies.length}`
    );
    const data = await response.data;
    // console.log("oldReplies<<", ...replies.replies);
    let oldReplies = [...replies.replies];
    oldReplies = oldReplies.concat(data.data);
    // console.log("oldReplies>>", oldReplies);
    await setReplies({ ...replies, replies: oldReplies });
  };

  const handleClose=()=>{
    console.log('length', replies.replies.length)
    setReplies({
      reply_count: 0,
      replies: [],
      forum_id: "",
    })
    setShowMoreRepliesDialog(false);
    setHasMore(true)
  }

  // console.log('first', replies.replies.length)
  
  const getForums = async (subcategory = "", page) => {
    try {
      let response: any;
      if (props.page == "forum-light") {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/forum/get-category`
        );
      } else if (props.page == "forum-category") {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/forum/get-sub-category/${subcategory}`
        );
      } else if (props.page == "forum-posts") {
        response = await axios.get(
          `${process.env.REACT_APP_API_URL}/forum/get-forum/${subcategory}`
        );
      }

      const data = await response.data;
      setForums(data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    //@ts-ignore
    getForums(props?.match?.params?.id || "", props.page);
    //@ts-ignore
  }, [props.refetch]);


  return (
    <>
      {props.page == "forum-light" && (
        <div className="ForumDetails">
          <div className="central-meta forumdetails-section">
            <div className="forum_details_header">
              <div className="row">
                <div className="col-8">
                  <p>Forum</p>
                </div>
                {/* <div className="col-2 text-right">
                  <p>Topics</p>
                </div> */}
                <div className="col-2 text-center">
                  <p>Posts</p>
                </div>
              </div>
            </div>
            <div className="forum_details_body">
              {
                //@ts-ignore
                forums.map((forum, index) => {
                  return (
                    <Link to={`/forum-category/${forum._id}`} key={forum._id}>
                      <div className="row forum_details">
                        <div className="col-8">
                          <p>
                            <img
                              src="/images/forumdetails_img.png"
                              alt=""
                              className="forum_details_image"
                            />
                            {forum.category_name}
                          </p>
                          <span>{forum.category_description}</span>
                        </div>
                        {/* <div className="col-2 text-right align-self-center text-dark">
                          {forum.topic_count}
                        </div> */}
                        <div className="col-2 text-center align-self-center text-dark">
                          {forum.total_post}
                        </div>
                      </div>
                    </Link>
                  );
                })
              }
            </div>
            {/* <div className="lodmore">
        <button className="btn-view btn-load-more"></button>
      </div> */}
          </div>
        </div>
      )}
      {props.page == "forum-category" && (
        <div className="ForumDetails">
          <div className="central-meta forumdetails-section">
            <div className="forum_details_header">
              <div className="row">
                <div className="col-8 text-center">
                  <p>Topics</p>
                </div>
                {/* <div className="col-2 text-right">
                  <p>Voice</p>
                </div> */}
                <div className="col-2 text-center">
                  <p>Posts</p>
                </div>
              </div>
            </div>
            <div className="forum_details_body">
              {
                //@ts-ignore
                forums.map((forum, index) => {
                  return (
                    <Link to={`/forum-posts/${forum._id}`} key={forum._id}>
                      <div className="row forum_details">
                        <div className="col-8 text-center">
                          <p>
                            <img
                              src="../images/forumdetails_img.png"
                              alt=""
                              className="forum_details_image"
                            />
                            {forum.subcategory_name}
                          </p>
                          <span>{forum.subcategory_description}</span>
                        </div>
                        {/* <div className="col-2 text-right align-self-center text-dark">
                          {forum.user_count}
                        </div> */}
                        <div className="col-2 text-center align-self-center text-dark">
                          {forum.post_count}
                        </div>
                      </div>
                    </Link>
                  );
                })
              }
            </div>
            {/* <div className="lodmore">
      <button className="btn-view btn-load-more"></button>
    </div> */}
          </div>
        </div>
      )}
      {props.page == "forum-posts" && (
        <div className="ForumDetails">
          <div className="central-meta forumdetails-section">
            <div className="m-2 ml-4">
              <i className="fa fa-star mr-1" style={{ color: "#FFA500" }}></i>
              Asset for creating a beautiful website
            </div>
            <div className="forum_details_header">
              <div className="row">
                <div className="col-2">
                  <p>Author</p>
                </div>
                <div className="col-3">
                  <p>Post Date</p>
                </div>
                <div className="col-7">
                  <p>Posts</p>
                </div>
              </div>
            </div>
            <div className="forum_details_body">
              {
                //@ts-ignore
                forums.map((forum, index) => {
                  // posts.map((forum, index) => {
                  return (
                    <React.Fragment key={forum._id}>
                      {/* <Link to={`/forum-category/${forum.id}`} key={forum.id}> */}
                      <div className="row forum_details">
                        <div className="col-2  text-center">
                          <p className="text-center" style={{ color: 'gray' }}>
                            {
                              (forum.added_by?.profile_pic) ?
                                <>
                                  <img
                                    src={forum.added_by?.profile_pic}
                                    alt="profile picture"
                                    className="forum_details_image"
                                    style={{
                                      height: "3rem",
                                      width: "3rem",
                                      borderRadius: "50%",
                                    }}
                                  />
                                </> : 'No image'
                            }

                          </p>
                          <p
                            style={{ color: "#FFCF34" }}
                          >{`${forum?.added_by?.f_name ?? ''} ${forum?.added_by?.l_name ?? ''}`}</p>
                          <p className="text-muted">
                            <em>{forum.role}</em>
                          </p>
                          <span></span>
                        </div>
                        <div className="col-3 align-self-center text-dark text-center">
                          {new Date(forum?.created_at).toDateString()}
                          <br />
                          <br />
                          <div
                            style={{ color: "#FFCF34", cursor: "pointer" }}
                            onClick={async () => {
                              setReplyObject({ forumId: forum._id, reply: "" });
                              setShowAddReplyDialog(true);
                              // console.log({ forumId: forum._id, reply: '' });
                            }}
                          >
                            <em>Reply</em>
                          </div>
                        </div>

                        <div className="col-7 align-self-center text-dark">
                          {forum.post}
                        </div>
                      </div>
                      <div>
                        {
                          //@ts-ignore
                          forum?.reply
                            .filter((el) => el.reply_by)
                            .map((reply, index) => {
                              // reply = reply.reply;
                              return (
                                <div
                                  className="row forum_details"
                                  key={reply?._id + index}
                                >
                                  <div className="col-2  text-center"></div>
                                  <div className="col-3 align-self-center text-dark text-center"></div>
                                  <div
                                    className="col-7 align-self-center text-dark"
                                    style={{
                                      borderRadius: "5px",
                                      backgroundColor: "hsl(64, 1%, 95%)",
                                    }}
                                  >
                                    <div className="row p-3 m-0">
                                      <img
                                        src={reply?.reply_by?.profile_pic}
                                        alt="profile photo"
                                        className="forum_details_image"
                                        style={{
                                          height: "3rem",
                                          width: "3rem",
                                          borderRadius: "50%",
                                        }}
                                      />
                                      <div className="col">
                                        <div>
                                          <p
                                            style={{
                                              color: "#FFCF34",
                                              fontWeight: 500,
                                            }}
                                          >{`${reply?.reply_by?.f_name} ${reply?.reply_by?.l_name}`}</p>
                                          <p className="text-muted">
                                            <em>{reply.role}</em>
                                          </p>
                                          <span></span>
                                        </div>
                                        <div>{reply.reply}</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                        }
                        {/* <div className="row" >
                          <div className="col-2  text-center"></div>
                          <div className="col-3 align-self-center text-dark text-center"></div>
                          <div className="align-right col-7 bg-primary">r
                            < button > askdh</button>
                          </div>
                        </div> */}
                        {
                          console.log('lklk', replies)
                        }
                        {forum.reply_count > 3 && (
                          <div className="row forum_details">
                            <div className="col-2  text-center"></div>
                            <div className="col-3 align-self-center text-dark text-center"></div>
                            <div
                              className="col-7 align-self-center text-dark"
                              style={{ borderRadius: "5px" }}
                            >
                              <div className="row">
                                <button
                                  style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #FFCC3A",
                                    color: "#FFCC3A",
                                    borderRadius: "5px",
                                    padding: "0.4rem 2rem",
                                  }}
                                  onClick={async () => {
                                    try {
                                      const response = await axios.get(
                                        `${process.env.REACT_APP_API_URL}/forum/get-reply/${forum._id}?offset=${replies.replies.length}`
                                      );
                                      const data = await response.data;
                                      console.log("thisone", data.data);
                                      setReplies({
                                        ...replies,
                                        replies: data.data,
                                        forum_id: forum._id,
                                        reply_count: forum.reply_count,
                                      });
                                      Swal.mixin({
                                        toast: true,
                                        position: "top-end",
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                          toast.addEventListener("mouseenter", Swal.stopTimer);
                                          toast.addEventListener("mouseleave", Swal.resumeTimer);
                                        },
                                      });
                                      setHasMore(true);
                                      setShowMoreRepliesDialog(true);
                                      
                                    } catch (err: any) {
                                      Swal.mixin({
                                        toast: true,
                                        position: "top-end",
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                          toast.addEventListener(
                                            "mouseenter",
                                            Swal.stopTimer
                                          );
                                          toast.addEventListener(
                                            "mouseleave",
                                            Swal.resumeTimer
                                          );
                                        },
                                      }).fire(
                                        "failed!",
                                        "Please try again laterrr",
                                        "error"
                                      );
                                    }
                                  }}
                                >
                                  See all replies
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <hr />
                    </React.Fragment>
                  );
                })
              }
            </div>
          </div>
        </div>
      )}
      {forums.length == 0 && (
        <div className="ForumDetails">
          <div className="forum_details_body">
            <div className="row forum_details">
              <div className="col-8">
                <h4>No content to show</h4>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        size="lg"
        show={showAddReplyDialog}
        onHide={() => {setShowAddReplyDialog(false);setReplies({
          reply_count: 0,
          replies: [],
          forum_id: "",
        })}}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header
          closeButton
          style={{ color: "white", background: "#FFCC3A" }}
        >
          <Modal.Title id="example-modal-sizes-title-lg">
            Add your reply
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              reply: "",
              tags: "",
            }}
            onSubmit={async (values, actions) => {
              let temp: any = {};
              for (let key in values) {
                if (values.hasOwnProperty(key)) {
                  temp[key] = values[key];
                }
              }
              temp.tags = temp.tags.length
                ? temp.tags.split(",").map((tag) => "#" + tag.trim())
                : [];
              const data = {
                ...temp,
                forum_id: replyObject.forumId,
              };
              try {
                const response: any = await axios.post(
                  `${process.env.REACT_APP_API_URL}/forum/forum-reply`,
                  data
                );
                if (response.data.status) {
                  Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  }).fire("Created", "", "success");
                  //@ts-ignore
                  await getForums(props?.match?.params?.id || "", props.page);
                } else
                  Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener("mouseenter", Swal.stopTimer);
                      toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                  }).fire("Failed!", response?.data?.message, "error");
              } catch (err) {
                Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  },
                }).fire("Failed!", "Please reload and try again.", "error");
              }
              actions.setSubmitting(false);
              setShowAddReplyDialog(false);
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              handleChange,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit} id="form">
                <Form.Group>
                  <span className="f-title">
                    <i className="fa fa-circle"></i>
                    Reply *
                  </span>
                  <Field
                    style={{
                      backgroundColor: "white",
                      border: "2px solid silver",
                    }}
                    type="text"
                    placeholder="Add your reply to the forum"
                    name="reply"
                    component="textarea"
                    required
                  />
                </Form.Group>
                <Form.Group>
                  <span className="f-title">
                    <i className="fa fa-circle"></i>
                    Tags
                  </span>
                  <Field
                    style={{
                      backgroundColor: "white",
                      border: "2px solid silver",
                    }}
                    type="text"
                    placeholder="Add optional tags (separated by comma, e.g. tag1, tag2)"
                    name="tags"
                    component="input"
                  />
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => setShowAddReplyDialog(false)}
            style={{
              color: "white",
              background: "#A5DAD0",
              border: "transparent",
              padding: "10px 50px",
              borderRadius: "30px",
            }}
          >
            Cancel
          </button>
          <button
            type="submit"
            form="form"
            style={{
              color: "white",
              background: "#FFCC3A",
              border: "transparent",
              padding: "10px 50px",
              borderRadius: "30px",
            }}
          >
            Reply
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={showMoreRepliesDialog}
        onHide={() => {
          setShowMoreRepliesDialog(false);
          setReplies({ ...replies, reply_count: 0, replies: [] });
        }}
        aria-labelledby="replies-lg"
      >
        <Modal.Header
          closeButton
          style={{ color: "white", background: "#FFCC3A" }}
        >
          <Modal.Title id="example-modal-sizes-title-lg">
            All replies
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="scrollableDiv" style={{ height: 400, overflow: "auto" }}>
            <InfiniteScroll
              style={{
                overflowX: "hidden",
              }}
              dataLength={replies.replies.length}
              next={fetchMoreData}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
              endMessage={
                <h5
                  style={{
                    marginLeft: "6.5%",
                    padding: "1rem 0rem",
                  }}
                >
                  You have seen all replies
                </h5>
              }
             // height={"75vh"}
              loader={'Loading...'}
            >
              {(replies.replies.length)&&
                replies.replies?.map((reply:any, index) => {
                  console.log('reply', reply)
                  return (
                            <React.Fragment key={reply?._id}>
                              <div className="row">  
                                <div
                                  className="col-10 align-self-center text-dark mt-2"
                                  style={{
                                    borderRadius: "5px",                                   
                                    backgroundColor: "hsl(64, 1%, 95%)",
                                  }}
                                >
                                  <div className="row p-3 m-0">
                                    {
                                      (reply?.reply?.reply_by?.profile_pic) ?
                                      <>
                                       <img
                                      src={reply?.reply?.reply_by?.profile_pic}
                                      alt=""
                                      className="forum_details_image"
                                      style={{
                                        height: "3rem",
                                        width: "3.5rem",
                                        borderRadius: "50%",
                                      }}
                                    />
                                      </>
                                      :
                                      <img src='/images/android.png'
                                      style={{
                                        height: "3rem",
                                        width: "3.5rem",
                                        borderRadius: "50%",
                                      }} />
                                    }
                                     
                                      
                                   
                                    <div className="col">
                                      <div>
                                        <p
                                          style={{ color: "#FFCF34", fontWeight: 500 }}
                                        >{`${reply?.reply?.reply_by?.f_name??''} ${reply?.reply?.reply_by?.l_name??''}`}</p>
                                        <p className="text-muted">
                                          <em>{reply.role}</em>
                                        </p>
                                      </div>
                                      <div style={{ marginTop: "-1rem" }}>
                                        {reply?.reply?.reply??''}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                    }
                )}
            </InfiniteScroll>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleClose}
            style={{
              color: "white",
              background: "#A5DAD0",
              border: "transparent",
              padding: "10px 50px",
              borderRadius: "30px",
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ForumDetails;
