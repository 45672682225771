import Axios from "axios";
import Swal from "sweetalert2";
import { getUserInfo } from "../actions/user";
import { SET_PROGRESS_PERCENT } from "./post";

export const CREATE_PATREON_PAGE_REQUEST = "CREATE_PATREON_PAGE_REQUEST";
export const CREATE_PATREON_PAGE_SUCCESS = "CREATE_PATREON_PAGE_SUCCESS";
export const CREATE_PATREON_PAGE_ERROR = "CREATE_PATREON_PAGE_ERROR";
export const CREATE_PATREON_EVENT_REQUEST = "CREATE_PATREON_EVENT_REQUEST";
export const CREATE_PATREON_EVENT_SUCCESS = "CREATE_PATREON_EVENT_SUCCESS";
export const CREATE_PATREON_EVENT_ERROR = "CREATE_PATREON_EVENT_ERROR";
export const UPDATE_PATREON_PAGE_REQUEST = "UPDATE_PATREON_PAGE_REQUEST";
export const UPDATE_PATREON_PAGE_SUCCESS = "UPDATE_PATREON_PAGE_SUCCESS";
export const UPDATE_PATREON_PAGE_ERROR = "UPDATE_PATREON_PAGE_ERROR";
export const CREATE_PATREON_PAGE_POST_REQUEST =
  "CREATE_PATREON_PAGE_POST_REQUEST";
export const CREATE_PATREON_PAGE_POST_SUCCESS =
  "CREATE_PATREON_PAGE_POST_SUCCESS";
export const CREATE_PATREON_PAGE_POST_ERROR = "CREATE_PATREON_PAGE_POST_ERROR";
export const REPORT_PATREON_PAGE_SUCCESS = "REPORT_PATREON_PAGE_SUCCESS";
export const REPORT_PATREON_PAGE_ERROR = "REPORT_PATREON_PAGE_ERROR";
export const REPORT_PATREON_PAGE_REQUEST = "REPORT_PATREON_PAGE_REQUEST";
export const SET_PROGRESS_PERCENTAGE = "SET_PROGRESS_PERCENTAGE";
export const GET_PATREON_PAGE_REQUEST = "GET_PATREON_PAGE_REQUEST";
export const GET_PATREON_PAGE_SUCCESS = "GET_PATREON_PAGE_SUCCESS";
export const GET_PATREON_PAGE_ERROR = "GET_PATREON_PAGE_ERROR";
export const GET_PATREON_PAGE_DETAILS_REQUEST =
  "GET_PATREON_PAGE_DETAILS_REQUEST";
export const GET_PATREON_PAGE_DETAILS_SUCCESS =
  "GET_PATREON_PAGE_DETAILS_SUCCESS";
export const GET_PATREON_PAGE_DETAILS_ERROR = "GET_PATREON_PAGE_DETAILS_ERROR";
export const GET_PATREON_PAGE_FOLLOWERS_REQUEST =
  "GET_PATREON_PAGE_FOLLOWERS_REQUEST";
export const GET_PATREON_PAGE_FOLLOWERS_SUCCESS =
  "GET_PATREON_PAGE_FOLLOWERS_SUCCESS";
export const GET_PATREON_PAGE_FOLLOWERS_ERROR =
  "GET_PATREON_PAGE_FOLLOWERS_ERROR";
export const GET_PATREON_PAGE_EVENTS_REQUEST =
  "GET_PATREON_PAGE_EVENTS_REQUEST";
export const GET_PATREON_PAGE_EVENTS_SUCCESS =
  "GET_PATREON_PAGE_EVENTS_SUCCESS";
export const GET_PATREON_PAGE_EVENTS_ERROR = "GET_PATREON_PAGE_EVENTS_ERROR";
export const GET_PATREON_PAGE_POSTS_REQUEST = "GET_PATREON_PAGE_POSTS_REQUEST";
export const GET_PATREON_PAGE_POSTS_SUCCESS = "GET_PATREON_PAGE_POSTS_SUCCESS";
export const GET_PATREON_PAGE_POSTS_ERROR = "GET_PATREON_PAGE_POSTS_ERROR";
export const GET_PATREON_SUGGESTED_PAGES_REQUEST =
  "GET_PATREON_SUGGESTED_PAGES_REQUEST";
export const GET_PATREON_SUGGESTED_PAGES_SUCCESS =
  "GET_PATREON_SUGGESTED_PAGES_SUCCESS";
export const GET_PATREON_SUGGESTED_PAGES_ERROR =
  "GET_PATREON_SUGGESTED_PAGES_ERROR";
export const ADD_PATREON_PAGE_LIKE_REQUEST = "ADD_PATREON_PAGE_LIKE_REQUEST";
export const ADD_PATREON_PAGE_LIKE_SUCCESS = "ADD_PATREON_PAGE_LIKE_SUCCESS";
export const ADD_PATREON_PAGE_LIKE_ERROR = "ADD_PATREON_PAGE_LIKE_ERROR";
export const UNLIKE_PATREON_PAGE_REQUEST = "UNLIKE_PATREON_PAGE_REQUEST";
export const UNLIKE_PATREON_PAGE_SUCCESS = "UNLIKE_PATREON_PAGE_SUCCESS";
export const UNLIKE_PATREON_PAGE_ERROR = "UNLIKE_PATREON_PAGE_ERROR";
export const FOLLOW_PATREON_PAGE_REQUEST = "FOLLOW_PATREON_PAGE_REQUEST";
export const FOLLOW_PATREON_PAGE_SUCCESS = "FOLLOW_PATREON_PAGE_SUCCESS";
export const FOLLOW_PATREON_PAGE_ERROR = "FOLLOW_PATREON_PAGE_ERROR";
export const UNFOLLOW_PATREON_PAGE_REQUEST = "UNFOLLOW_PATREON_PAGE_REQUEST";
export const UNFOLLOW_PATREON_PAGE_SUCCESS = "UNFOLLOW_PATREON_PAGE_SUCCESS";
export const UNFOLLOW_PATREON_PAGE_ERROR = "UNFOLLOW_PATREON_PAGE_ERROR";
export const BLOCK_PATREON_PAGE_REQUEST = "BLOCK_PATREON_PAGE_REQUEST";
export const BLOCK_PATREON_PAGE_SUCCESS = "BLOCK_PATREON_PAGE_SUCCESS";
export const BLOCK_PATREON_PAGE_ERROR =
  "BLOCK_BLOCK_PATREON_PAGE_ERRORPAGE_ERROR";
export const UPDATE_PATREON_PAGE_COVER_PHOTO_REQUEST =
  "UPDATE_PATREON_PAGE_COVER_PHOTO_REQUEST";
export const UPDATE_PATREON_PAGE_COVER_PHOTO_ERROR =
  "UPDATE_PATREON_PAGE_COVER_PHOTO_ERROR";
export const UPDATE_PATREON_PAGE_COVER_PHOTO_SUCCESS =
  "UPDATE_PATREON_PAGE_COVER_PHOTO_SUCCESS";

// export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
// export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
// export const ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";
// export const EDIT_POST_REQUEST = "EDIT_POST_REQUEST";
// export const EDIT_POST_SUCCESS = "EDIT_POST_SUCCESS";
// export const EDIT_POST_ERROR = "EDIT_POST_ERROR";
export const DELETE_PATREON_POST_REQUEST = "DELETE_PATREON_POST_REQUEST";
export const DELETE_PATREON_POST_SUCCESS = "DELETE_PATREON_POST_SUCCESS";
export const DELETE_PATREON_POST_ERROR = "DELETE_PATREON_POST_ERROR";
export const TOTAL_VIDEO = "TOTAL_VIDEO";
export const TOTAL_PHOTO = "TOTAL_PHOTO";
// export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
// export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
// export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";
// export const UNLIKE_REQUEST = "UNLIKE_REQUEST";
// export const UNLIKE_SUCCESS = "UNLIKE_SUCCESS";
// export const UNLIKE_ERROR = "UNLIKE_ERROR";
// export const LIKE_COMMENT_SUCCESS = "LIKE_COMMENT_SUCCESS";
// export const LIKE_COMMENT_REQUEST = "LIKE_COMMENT_REQUEST";
// export const LIKE_COMMENT_ERROR = "LIKE_COMMENT_ERROR";
// export const UNLIKE_COMMENT_REQUEST = "UNLIKE_COMMENT_REQUEST";
// export const UNLIKE_COMMENT_SUCCESS = "UNLIKE_COMMENT_SUCCESS";
// export const UNLIKE_COMMENT_ERROR = "UNLIKE_COMMENT_ERROR";
// export const GET_POST_TOTAL = "GET_POST_TOTAL";
// export const GET_POST_TOTAL_SUCCESS = "GET_POST_TOTAL_SUCCESS";
// export const GET_POST_TOTAL_ERROR = "GET_POST_TOTAL_ERROR";
// export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
// export const GET_ALL_COMMENTS_SUCCESS = "GET_ALL_COMMENTS_SUCCESS";
// export const GET_ALL_COMMENTS_ERROR = "GET_ALL_COMMENTS_ERROR";

export const createPatreonPage = (data: any) =>
  async (dispatch: any, getState: any) => {
    console.log("create-->", data);
    await dispatch({ type: CREATE_PATREON_PAGE_REQUEST, payload: data });
    console.log(data);
    try {
      const response: any = await Axios.post(
        `${process.env.REACT_APP_API_URL}/patreon/create`,
        data,
      );
      const realData = await response.data;
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Page Created", "", "success");

      return dispatch({
        type: CREATE_PATREON_PAGE_SUCCESS,
        payload: realData.data,
      });
    } catch (err) {
      console.log(err);
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Page creation failed!", "Please reload and try again.", "error");
      return dispatch({ type: CREATE_PATREON_PAGE_ERROR, payload: err });
    }
  };

// export const reportPage =
//   (data: any) => async (dispatch: any, getState: any) => {
//     await dispatch({ type: REPORT_PAGE_REQUEST, payload: data });
//     try {
//       console.log("working");
//       const response: any = await Axios.post(
//         `${process.env.REACT_APP_API_URL}/fav-page/report-a-page`,
//         data
//       );
//       const realData = await response.data;
//       Swal.mixin({
//         toast: true,
//         position: "top-end",
//         showConfirmButton: false,
//         timer: 3000,
//         timerProgressBar: true,
//         didOpen: (toast) => {
//           toast.addEventListener("mouseenter", Swal.stopTimer);
//           toast.addEventListener("mouseleave", Swal.resumeTimer);
//         },
//       }).fire("Report Page", "", "success");

//       return dispatch({
//         type: REPORT_PAGE_SUCCESS,
//         payload: realData.data,
//       });
//     } catch (err) {
//       console.log(err);
//       Swal.mixin({
//         toast: true,
//         position: "top-end",
//         showConfirmButton: false,
//         timer: 3000,
//         timerProgressBar: true,
//         didOpen: (toast) => {
//           toast.addEventListener("mouseenter", Swal.stopTimer);
//           toast.addEventListener("mouseleave", Swal.resumeTimer);
//         },
//       }).fire("Page Report failed!", "Please reload and try again.", "error");
//       return dispatch({ type: REPORT_PAGE_ERROR, payload: err });
//     }
//   };

// export const createEvent =
//   (data: any) => async (dispatch: any, getState: any) => {
//     await dispatch({ type: CREATE_EVENT_REQUEST, payload: data });
//     try {
//       const response: any = await Axios.post(
//         `${process.env.REACT_APP_API_URL}/fav-page/create-event`,
//         data
//       );
//       const realData = await response.data;
//       if (realData.status) {
//         Swal.mixin({
//           toast: true,
//           position: "top-end",
//           showConfirmButton: false,
//           timer: 3000,
//           timerProgressBar: true,
//           didOpen: (toast) => {
//             toast.addEventListener("mouseenter", Swal.stopTimer);
//             toast.addEventListener("mouseleave", Swal.resumeTimer);
//           },
//         }).fire("Event Created", "", "success");

//         return dispatch({
//           type: CREATE_EVENT_SUCCESS,
//           payload: realData.data,
//         });
//       } else {
//         Swal.mixin({
//           toast: true,
//           position: "top-end",
//           showConfirmButton: false,
//           timer: 3000,
//           timerProgressBar: true,
//           didOpen: (toast) => {
//             toast.addEventListener("mouseenter", Swal.stopTimer);
//             toast.addEventListener("mouseleave", Swal.resumeTimer);
//           },
//         }).fire(
//           "Event creation failed!",
//           "Please reload and try again.",
//           "error"
//         );
//         return dispatch({
//           type: CREATE_EVENT_ERROR,
//           payload: response.message,
//         });
//       }
//     } catch (err) {
//       console.log(err);
//       Swal.mixin({
//         toast: true,
//         position: "top-end",
//         showConfirmButton: false,
//         timer: 3000,
//         timerProgressBar: true,
//         didOpen: (toast) => {
//           toast.addEventListener("mouseenter", Swal.stopTimer);
//           toast.addEventListener("mouseleave", Swal.resumeTimer);
//         },
//       }).fire(
//         "Event creation failed!",
//         "Please reload and try again.",
//         "error"
//       );
//       return dispatch({ type: CREATE_EVENT_ERROR, payload: err });
//     }
//   };
export const updatePatreonPageDetails = (data: any, page: number) =>
  async (dispatch: any, getState: any) => {
    await dispatch({ type: UPDATE_PATREON_PAGE_REQUEST, payload: data });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/fav-page/update-page-details`,
        data,
      );
      const realData = await response.data;
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Page updated!", "", "success");

      await dispatch(getPatreonPageDetails(data.page_id));
    } catch (err) {
      console.log(err);
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Page creation failed!", "Please reload and try again.", "error");
      return dispatch({ type: UPDATE_PATREON_PAGE_ERROR, payload: err });
    }
  };

export const createPatreonPagePost = (data: any, type: string, page: number) =>
  async (dispatch: any, getState: any) => {
    await dispatch({ type: CREATE_PATREON_PAGE_POST_REQUEST, payload: data });
    try {
      dispatch({
        type: SET_PROGRESS_PERCENT,
        payload: 0,
      });
      const response: any = await Axios.post(
        `${process.env.REACT_APP_API_URL}/patreon/create-post`,
        data,
        {
          headers: {
            "Content-Type": "multipart/gorm-data",
          },
          onUploadProgress: (progressEvent) => {
            var cal = Math.round(
              Number(progressEvent.loaded * 100) / Number(progressEvent.total),
            );
            console.log("progressEvent", cal);
            dispatch({
              type: SET_PROGRESS_PERCENT,
              payload: cal,
            });
          },
        },
      );
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Posting", "", "success");
      dispatch(getPatreonPagePosts(data.get("page_id"), type, false, page));
      return dispatch({
        type: CREATE_PATREON_PAGE_POST_SUCCESS,
        payload: response.data.data,
      });
    } catch (err) {
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Upload Failed!", "Please reload and try again.", "error");
      return dispatch({ type: CREATE_PATREON_PAGE_POST_ERROR, payload: err });
    }
  };

export const deletePatreonPost = (
  postId: string,
  type: string,
  pageId: any,
  page: number,
) =>
  async (dispatch: any) => {
    console.log("API calling..");
    await dispatch({ type: DELETE_PATREON_POST_REQUEST, payload: postId });
    try {
      const response: any = await Axios.delete(
        `${process.env.REACT_APP_API_URL}/patreon/post/${postId}`,
      );
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Deleting", "", "success");
      dispatch(getPatreonPagePosts(pageId, type, false, page));
      return dispatch({
        type: DELETE_PATREON_POST_SUCCESS,
        payload: { data: response.data, postId },
      });
    } catch (err) {
      return dispatch({ type: DELETE_PATREON_POST_ERROR, payload: err });
    }
  };

export const getPatreonPages = (id: string, offset = 0) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    await dispatch({ type: GET_PATREON_PAGE_REQUEST, payload: id });
    const url =
      `${process.env.REACT_APP_API_URL}/patreon/get-page?offset=${offset}`;
    console.log("Patreon URL:", url);
    try {
      const response: any = await Axios.get(url);
      const data = await response.data;
      console.log("Patreons::::", data);
      return dispatch({
        type: GET_PATREON_PAGE_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_PATREON_PAGE_ERROR, payload: err });
    }
  };
export const getPatreonPageDetails = (id: string) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    await dispatch({ type: GET_PATREON_PAGE_DETAILS_REQUEST, payload: id });
    const url =
      `${process.env.REACT_APP_API_URL}/patreon/get-page-details/${id}`;
    try {
      const response: any = await Axios.get(url);
      const data = await response.data;
      return dispatch({
        type: GET_PATREON_PAGE_DETAILS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_PATREON_PAGE_DETAILS_ERROR, payload: err });
    }
  };
export const getPatreonPagePosts = (
  id: string,
  type: string,
  isPatreonPage: boolean,
  page: number,
) =>
  async (dispatch: any, getState: any) => {
    console.log("type", type, page);
    const state = getState();
    await dispatch({ type: GET_PATREON_PAGE_POSTS_REQUEST, payload: id });
    const url =
      `${process.env.REACT_APP_API_URL}/patreon/get-post/${id}?offset=${((page -
        1) * 10)}&type=${type}`;
    try {
      const response: any = await Axios.get(url);
      const data = await response.data;
      console.log("data", data.data.total);

      if (type === "image") {
        dispatch({
          type: TOTAL_PHOTO,
          payload: data.data.total,
        });

        const url1 =
          `${process.env.REACT_APP_API_URL}/patreon/get-post/${id}?offset=${((page -
            1) * 10)}&type=video`;
        const response1: any = await Axios.get(url1);
        const data1 = await response1.data;
        console.log("REDUX VIDEO DATA", data1);

        dispatch({
          type: TOTAL_VIDEO,
          payload: data1.data.total,
        });
      }
      if (type === "video") {
        dispatch({
          type: TOTAL_VIDEO,
          payload: data.data.total,
        });
        const url1 =
          `${process.env.REACT_APP_API_URL}/patreon/get-post/${id}?offset=${((page -
            1) * 10)}&type=image`;
        const response1: any = await Axios.get(url1);
        const data1 = await response1.data;
        dispatch({
          type: TOTAL_PHOTO,
          payload: data1.data.total,
        });
      }

      return dispatch({
        type: GET_PATREON_PAGE_POSTS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_PATREON_PAGE_POSTS_ERROR, payload: err });
    }
  };
// export const getPatreonPagePosts =
//   (id: string) => async (dispatch: any, getState: any) => {
//     const state = getState();
//     await dispatch({ type: GET_PATREON_PAGE_POSTS_REQUEST, payload: id });
//     const url = `${process.env.REACT_APP_API_URL}/fav-page/get-post/${id}?offset=0`;
//     try {
//       const response: any = await Axios.get(url);
//       const data = await response.data;
//       return dispatch({
//         type: GET_PAGE_POSTS_SUCCESS,
//         payload: data.data,
//       });
//     } catch (err) {
//       return dispatch({ type: GET_PAGE_POSTS_ERROR, payload: err });
//     }
//   };
// export const getPageFollowers =
//   (id: string) => async (dispatch: any, getState: any) => {
//     const state = getState();
//     await dispatch({ type: GET_PAGE_FOLLOWERS_REQUEST, payload: id });
//     const url = `${process.env.REACT_APP_API_URL}/fav-page/get-follower/${id}?offset=0`;
//     try {
//       const response: any = await Axios.get(url);
//       const data = await response.data;

//       return dispatch({
//         type: GET_PAGE_FOLLOWERS_SUCCESS,
//         payload: data.data,
//       });
//     } catch (err) {
//       return dispatch({ type: GET_PAGE_FOLLOWERS_ERROR, payload: err });
//     }
//   };
// export const getPageEvents =
//   (id: string) => async (dispatch: any, getState: any) => {
//     const state = getState();
//     await dispatch({ type: GET_PAGE_EVENTS_REQUEST, payload: id });
//     const url = `${process.env.REACT_APP_API_URL}/fav-page/event-list/${id}?offset=0`;
//     try {
//       const response: any = await Axios.get(url);
//       const data = await response.data;

//       return dispatch({
//         type: GET_PAGE_EVENTS_SUCCESS,
//         payload: data.data,
//       });
//     } catch (err) {
//       return dispatch({ type: GET_PAGE_EVENTS_ERROR, payload: err });
//     }
//   };
// export const getSuggestedPages =
//   (pageNumber = 1) =>
//     async (dispatch: any) => {
//       await dispatch({ type: GET_SUGGESTED_PAGES_REQUEST });
//       try {
//         const response: any = await Axios.get(
//           `${process.env.REACT_APP_API_URL}/fav-page/get-suggestion-pages?offset=0`
//         );
//         return dispatch({
//           type: GET_SUGGESTED_PAGES_SUCCESS,
//           payload: response.data.data,
//         });
//       } catch (err) {
//         return dispatch({ type: GET_SUGGESTED_PAGES_ERROR, payload: err });
//       }
//     };
// export const addPageLike =
//   (id: string) => async (dispatch: any, getState: any) => {
//     await dispatch({ type: ADD_PAGE_LIKE_REQUEST, payload: id });
//     try {
//       const response: any = await Axios.put(
//         `${process.env.REACT_APP_API_URL}/fav-page/like/${id}/like`
//       );
//       await dispatch(getPageDetails(id));
//       return dispatch({
//         type: ADD_PAGE_LIKE_SUCCESS,
//         payload: { data: response.data.data, postId: id },
//       });
//     } catch (err) {
//       return dispatch({ type: ADD_PAGE_LIKE_ERROR, payload: err });
//     }
//   };

// export const removePageLike =
//   (id: string) => async (dispatch: any, getState: any) => {
//     await dispatch({ type: UNLIKE_PAGE_REQUEST, payload: id });
//     try {
//       const response: any = await Axios.put(
//         `${process.env.REACT_APP_API_URL}/fav-page/like/${id}/unlike`
//       );
//       return dispatch({
//         type: UNLIKE_PAGE_SUCCESS,
//         payload: { data: response.data.data, postId: id },
//       });
//     } catch (err) {
//       return dispatch({ type: UNLIKE_PAGE_ERROR, payload: err });
//     }
//   };
// export const followPage =
//   (id: string) => async (dispatch: any, getState: any) => {
//     await dispatch({ type: FOLLOW_PAGE_REQUEST, payload: id });
//     try {
//       const response: any = await Axios.put(
//         `${process.env.REACT_APP_API_URL}/fav-page/follow/${id}/follow`
//       );
//       return dispatch({
//         type: FOLLOW_PAGE_SUCCESS,
//         payload: { data: response.data.data, postId: id },
//       });
//     } catch (err) {
//       return dispatch({ type: FOLLOW_PAGE_ERROR, payload: err });
//     }
//   };
// export const unfollowPage =
//   (id: string) => async (dispatch: any, getState: any) => {
//     await dispatch({ type: UNFOLLOW_PAGE_REQUEST, payload: id });
//     try {
//       const response: any = await Axios.put(
//         `${process.env.REACT_APP_API_URL}/fav-page/follow/${id}/unfollow`
//       );
//       return dispatch({
//         type: UNFOLLOW_PAGE_SUCCESS,
//         payload: { data: response.data.data, postId: id },
//       });
//     } catch (err) {
//       return dispatch({ type: UNFOLLOW_PAGE_ERROR, payload: err });
//     }
//   };
// export const blockPage =
//   (id: string) => async (dispatch: any, getState: any) => {
//     await dispatch({ type: BLOCK_PAGE_REQUEST, payload: id });
//     try {
//       const response: any = await Axios.put(
//         `${process.env.REACT_APP_API_URL}/fav-page/block/${id}/block`
//       );
//       return dispatch({
//         type: BLOCK_PAGE_SUCCESS,
//         payload: { data: response.data.data, postId: id },
//       });
//     } catch (err) {
//       return dispatch({ type: BLOCK_PAGE_ERROR, payload: err });
//     }
//   };

// export const updatePageMedia =
//   (id: string, coverphoto: any, type: string) =>
//     async (dispatch: any, getState: any) => {
//       await dispatch({ type: UPDATE_PAGE_COVER_PHOTO_REQUEST });
//       try {
//         const formData = new FormData();
//         formData.append("photo", coverphoto);
//         formData.append("page_id", id);

//         const config = {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         };
//         let url = "";
//         if (type == "cover") {
//           url = `${process.env.REACT_APP_API_URL}/fav-page/upload-cover-photo`;
//         } else {
//           url = `${process.env.REACT_APP_API_URL}/fav-page/upload-profile-photo`;
//         }

//         const response: any = await Axios.put(url, formData, config);
//         Swal.mixin({
//           toast: true,
//           position: "top-end",
//           showConfirmButton: false,
//           timer: 3000,
//           timerProgressBar: true,
//           didOpen: (toast) => {
//             toast.addEventListener("mouseenter", Swal.stopTimer);
//             toast.addEventListener("mouseleave", Swal.resumeTimer);
//           },
//         }).fire("Looks great, Updated!", "", "success");
//         dispatch(getPageDetails(id));

//         return dispatch({
//           type: UPDATE_PAGE_COVER_PHOTO_SUCCESS,
//           payload: response.data,
//         });
//       } catch (err: any) {
//         return dispatch({ type: UPDATE_PAGE_COVER_PHOTO_ERROR, payload: err });
//       }
//     };
