import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Dropdown, DropdownButton, Tab, Nav } from "react-bootstrap";
import Header from "../components/header";
import * as io from "socket.io-client";
import { saveWebSocket,initsocket } from "../actions";
import AuthService from "./AuthService";
function WebSocketService(props) {

  const [connectedsocket, setsocket] = useState<any>(null)
  
  useEffect(() => {
    if (AuthService.isAuthenticated()&&props.userInfo) {
      const socket =  io.connect(`wss://api.epic.flowzcluster.tk/socket_chat`,{
        query:{id:props.userInfo._id}
      });
      console.log('this is caht chat hcat hca cha hfsfdfs')
      setsocket(socket);
     // connectsocket(socket);
      props.saveWebSocket(socket);     
    }
    return () => {
      if (connectedsocket) {
        connectedsocket.disconnect();
      }
    }
  }, [props?.userInfo])

  useEffect(() => {
    console.log(props,'propspropspropspropspropspropsprops234567')

    if(props.socket){
      props.initsocket();   
    }
  }, [props.socket])
  return (
    <></>
  )
}

const mapStateToProps = (state: any) => {
  return {
    userInfo: state.user.data,
    socket:state.chat.socket
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    saveWebSocket: (socket) => dispatch(saveWebSocket(socket)),
    initsocket:()=>dispatch(initsocket())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WebSocketService)