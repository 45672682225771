import {
  CREATE_POST_ERROR,
  CREATE_POST_REQUEST,
  CREATE_POST_SUCCESS,
  ADD_COMMENT_ERROR,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_LIKE_ERROR,
  ADD_LIKE_REQUEST,
  ADD_LIKE_SUCCESS,
  GET_POST_REQUEST,
  GET_POST_SUCCESS,
  GET_POST_ERROR,
  DELETE_POST_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  UNLIKE_SUCCESS,
  LIKE_COMMENT_SUCCESS,
  UNLIKE_COMMENT_SUCCESS,
  GET_POST_TOTAL_SUCCESS,
  GET_ALL_COMMENTS_SUCCESS,
  SET_PROGRESS_PERCENT,
} from "../actions/post";

const postReducer = (state: any = {}, action: any) => {
  let data;
  switch (action.type) {
    case CREATE_POST_REQUEST:
    case GET_POST_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_POST_SUCCESS:
      data = state.data;
      data.unshift(action.payload);
      return {
        ...state,
        data,
        isLoading: false,
        isSuccess: true,
      };
    case CREATE_POST_ERROR:
    case GET_POST_ERROR:
      return {
        ...state,
        isLoading: false,
        err: action.payload,
      };
    case GET_POST_SUCCESS:
      return {
        ...state,
        data: action.payload.posts,
        count: action.payload.total,
      };
    case ADD_LIKE_SUCCESS:
      if (window.location.pathname.includes("favorite"))
      
        data = state.posts.posts.map((val: any) => {
          console.log('ccc',val)
          if (val._id === action.payload.postId) {
            val.likes += 1;
            val.liked = true;
          }
          return val;
        });
      else
        data = state.data.map((val: any) => {
          if (val._id === action.payload.postId) {
            val.likes += 1;
            val.liked = true;
          }
          return val;
        });
      return {
        ...state,
        data,
      };
    case UNLIKE_SUCCESS:
      data = state.data.map((val: any) => {
        if (val._id === action.payload.postId) {
          val.likes -= 1;
          val.liked = false;
        }
        return val;
      });
      return {
        ...state,
        data,
      };
    case LIKE_COMMENT_SUCCESS:
      data = state.data.map((val: any) => {
        val.comments.map((comment: any) => {
          if (comment._id === action.payload.commentId) {
            comment.likes.push({ _id: localStorage.getItem("user_id") });
            comment.liked = true;
          }
        });
        return val;
      });
      return {
        ...state,
        data,
      };
    case UNLIKE_COMMENT_SUCCESS:
      data = state.data.map((val: any) => {
        val.comments.map((comment: any) => {
          if (comment._id === action.payload.commentId) {
            comment.likes = comment.likes.filter(
              (like) => like._id !== localStorage.getItem("user_id")
            );
            comment.liked = false;
          }
        });
        return val;
      });
      return {
        ...state,
        data,
      };
    case ADD_COMMENT_SUCCESS:
      data = state.data.map((val: any) => {
        if (val._id === action.payload.postId) {
          if (val.commentsCount) val.commentsCount += 1;
          else val.commentsCount = 1;
          if (
            val.comments &&
            Array.isArray(val.comments) &&
            action.payload.data &&
            action.payload.data.comment
          )
            val.comments.push(action.payload.data);
        }
        return val;
      });
      return {
        ...state,
        data,
      };
    case DELETE_POST_SUCCESS:
      if (window.location.pathname.includes("favorite")) {
        data = state?.posts?.posts.filter((val: any) => {
          return val._id !== action.payload.postId;
        });
        return {
          ...state,
          count: state.count - 1,
          data,
        };
      }
      data = state.data.filter((val: any) => {
        return val._id !== action.payload.postId;
      });
      return {
        ...state,
        count: state.count - 1,
        data,
      };
    case DELETE_COMMENT_SUCCESS:
      data = state.data.filter((val: any) => {
        if (val._id === action.payload.postId) {
          val.comments = val.comments.filter((comment: any) => {
            return comment._id !== action.payload.commentId;
          });
        }
        return true;
      });
      return {
        ...state,
        data,
      };
    case GET_POST_TOTAL_SUCCESS:
      return {
        ...state,
        total: action.payload.data,
      };
    case GET_ALL_COMMENTS_SUCCESS:
      data = state.data.map((val: any) => {
        if (val._id === action.payload.postId) {
          val.comments = action.payload.data.comments;
        }
        return val;
      });
      return {
        ...state,
        data,
      };
      case SET_PROGRESS_PERCENT:
        return {
          ...state,
          percentage: action.payload,
        };

    default:
      return state;
  }
};

export default postReducer;
