import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './ForumSlider.scss';
import PropTypes from './types';

const ForumSlider: React.FC<PropTypes> = (props) => (
  <div className="ForumSlider">
    <div className="main-background">
      <Row>
        <Col xl={1} md={1}></Col>
        <Col className="text-center" xl={4} lg={4} md={12}>
          <img src="../images/forum-mainimg.png" alt="some random iamge" className="forum-image" />
        </Col>
        <Col className="align-self-center mainsection-content" xl={6} lg={6} md={12}>
          <div className="content-section text-center">
            <h3 className="forumslider-header mb-4">{props.page === 'forum-light' ? "Forum" : "Support And Help"}</h3>
            {/* <div className="input-group  mb-4">
              <input type="text" className="form-control supprt_search" placeholder="Search this blog" />
              <div className="input-group-append">
                <button className="btn supprt_searchbtn" type="button">
                  <i className="fa fa-search"></i>
                </button>
              </div>
            </div> */}
            <p className="forum-content font-bold">Welcome to Epic Much Social Network. Here you’ll find all questions about the epicmuchlogo usages in the whole template and ready made elemets as you want.</p>
          </div>
        </Col>
        <Col className="" xl={1} md={1}></Col>
      </Row>
    </div>
  </div>
);

export default ForumSlider;
