import React, { useEffect, useState, Fragment } from "react";
import HeaderProps from "./types";
import "./header.scss";
import { Link, Redirect, useHistory } from "react-router-dom";
import moment from "moment";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { Dropdown } from "react-bootstrap";
import Toast from "react-bootstrap/Toast";
import axios from "axios";
import FvrtPostView from "../FvrtPost/FvrtPost";
// import FvrtPostView from "../FvrtPost/FvrtPost";

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    //@ts-ignore
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
}

const user_Id = localStorage.getItem("user_id");


const Header: React.FC<HeaderProps> = (props) => {
  let history = useHistory();

  const {
    friendRequests,
    notifications,
    acceptFriendRequest,
    rejectFriendRequest,
    isDeleted,
    logout,
    clearUserState,
    toaster,
    getUserInfo
  } = props;
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [deletedAccount, setDeletedAccount] = useState(false);
  const [userId, setUserId] = useState("");
  const [notificationPost, setNotificationPost] = useState(false);
  const [selectedNotificationPost, setSelectedNotificationPost] = useState({});

  useEffect(() => {
    const profileId = props.match?.params?.id;
    if (props.getChats) props.getChats(0);
    if (props.getUserInfo) props.getUserInfo(profileId);
    if (props.getAllFriendRequest) props.getAllFriendRequest();
    if (props.getAllNotification) props.getAllNotification();
  }, [props.match?.params.id]);

  // useEffect(() => {
  //   if (isDeleted) setDeletedAccount(isDeleted);
  // }, [isDeleted]);

  useEffect(() => {
    return history.listen((location: any) => {
      if (
        !(
          location.pathname.includes("favorite") ||
          location.pathname.includes("events") ||
          location.pathname.includes("followers")
        )
      ) {
        if (
          (location.pathname.includes("/settings") &&
            props.match?.url.includes("/settings")) ||
          (props.match && location.pathname === props.match?.url)
        ) {
        } else if (clearUserState) clearUserState();
      }
    });
  }, [history]);

  useEffect(() => {
    async function fetchData() {
      try {
        const id = localStorage.getItem("user_id");
        let url = `${process.env.REACT_APP_API_URL}/profile/${id}`;
        const response: any = await axios.get(url);
        setUserId(response.data.data.user_id);
      } catch (err: any) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  const openPost = (notification, index) => {
    if ((notification.notificationObject?.type === "friend_request_accept") || (notification.notificationObject?.type === "friend_request")) {
      history.push(`/timeline/${notification.noti_from_id._id}`)
    }
    else {
      setNotificationPost(true)
      setSelectedNotificationPost({ notification, index })
    }
  }



  //@ts-ignore
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      //@ts-ignore
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <i className="fa fa-user"></i>
      <em className="bg-red">{friendRequests ? friendRequests.length : 0}</em>
      {children}
    </a>
  ));
  //@ts-ignore
  const MoreOptionsToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      title="Help"
      //@ts-ignore
      ref={ref}
      onClick={(e) => {
        //@ts-ignore
        e.preventDefault();
        //@ts-ignore
        onClick(e);
      }}
    >
      <i
        className="fa fa-question"
        style={{
          padding: "8px 13px",
        }}
      ></i>
      {children}
    </a>
  ));
  //@ts-ignore
  const CustomNotificationToggle = React.forwardRef(
    //@ts-ignore
    ({ children, onClick }, ref) => (
      <a
        href=""
        //@ts-ignore
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          axios
            .put(`/api/notification/seen`)
            .then((res) => { })
            .catch((err) => { });
          onClick(e);
        }}
      >
        <i className="fa fa-bell"></i>
        <em className="bg-red">
          {notifications ? notifications?.filter((el) => !el.seen).length : 0}
        </em>
        {children}
      </a>
    )
  );

  const handleLogout = () => {
    const myItem = localStorage.getItem("remember");
    localStorage.clear();
    if (myItem) localStorage.setItem("remember", myItem);
    if (logout) logout();
  };

  const handleSearch = async (query: any) => {
    setIsLoading(true);

    try {
      const resp = await fetch(
        `${process.env.REACT_APP_API_URL}/searchByName/all?name=${query}`,
        {
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          }),
        }
      );
      const data = await resp.json();
      let tempOptions = await data.data.map((i: any) => ({
        avatar_url: i.profile_pic,
        id: i.profile_id,
        login: i.f_name + " " + i.l_name,
      }));
      setOptions(tempOptions);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };
  const debounceHandleSearch = React.useCallback(
    debounce(handleSearch, 400),
    []
  );

  const filterBy = () => true;

  if (deletedAccount) {
    return <Redirect to="/login" />;
  } else {
    return (
      <>
        <div className="topbar stick">
          <div className="logo col-sm-3">
            <Link to={`/landing`}>
              <img src="/images/logo.png" alt="" className="logo-img" />
            </Link>
          </div>
          <div className="top-area col-sm-6">
            <div className="top-search">
              <form method="post" className="">
                <AsyncTypeahead
                  filterBy={filterBy}
                  id="async-example"
                  isLoading={isLoading}
                  labelKey="login"
                  minLength={1}
                  onSearch={debounceHandleSearch}
                  options={options}
                  placeholder="Search People"
                  renderMenuItemChildren={(option: any, props: any) => (
                    <Link
                      to={`/timeline/${option.id}`}
                      style={{
                        display: "flex",
                        margin: "-8px 0px",
                        padding: "8px 0px",
                      }}
                    >
                      <img
                        alt={"profile"}
                        src={
                          option.avatar_url || "https://via.placeholder.com/150"
                        }
                        style={{
                          height: "24px",
                          marginRight: "10px",
                          width: "24px",
                        }}
                        //@ts-ignore
                        onError={(e) => {
                          //@ts-ignore
                          e.target.onerror = null;
                          //@ts-ignore
                          e.target.src =
                            "/images/default-avatar-placeholder.png";
                        }}
                      />
                      <div style={{ width: "100%", marginLeft: "1rem" }}>
                        {option.login}
                      </div>
                    </Link>
                  )}
                />
                <button data-ripple="">
                  <i className="ti-search"></i>
                </button>
              </form>
            </div>
          </div>
          <div className="top-area col-sm-3">
            <ul className="setting-area">
              <li>
                <Link
                  to={"/landing"}
                  className="header-icon"
                  title="Home"
                  data-ripple=""
                >
                  <i
                    className="fa fa-home"
                    style={{
                      color:
                        window.location.pathname === "/landing"
                          ? "#a5dad0"
                          : "",
                    }}
                  ></i>
                </Link>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    title="Friend Requests"
                    as={CustomToggle}
                  ></Dropdown.Toggle>

                  <Dropdown.Menu className="dropdowns ps-container ps-theme-default ps-active-y drops-menu">
                    <Dropdown.Item disabled>
                      <span>
                        {friendRequests.length} Pending Requests
                        {/* <a href="#" title="">View all Requests</a> */}
                      </span>
                    </Dropdown.Item>
                    {!!friendRequests.length && (
                      <>
                        {friendRequests.map((friend: any) => {
                          return (
                            <React.Fragment key={friend?._id}>
                              <Dropdown.Divider />
                              <Dropdown.Item>
                                <li>

                                  <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                                    <Link to={`/about/${friend?._id}`} style={{ display: 'flex' }}>
                                      <div>
                                        <figure>
                                          <img
                                            src={friend?.profile_pic}
                                            alt=""
                                            className="frd-reg-img rounded-circle"
                                            //@ts-ignore
                                            onError={(e) => {
                                              //@ts-ignore
                                              e.target.onerror = null;
                                              //@ts-ignore
                                              e.target.src =
                                                "/images/default-avatar-placeholder.png";
                                            }}
                                          />
                                        </figure>
                                      </div>

                                      <div className="mesg-meta mesg-meta-fr-dropdown" >
                                        <p>
                                          {friend?.f_name} {friend?.l_name}
                                        </p>
                                        {friend?.mutualfriends !== 0 && (
                                          <span>
                                            <b>{friend?.mutualfriends}</b> mutual
                                            friends
                                          </span>
                                        )}
                                        <small>
                                          <i>
                                            {/* {moment([new Date(friend.since).getFullYear(), new Date(friend.since).getMonth(), new Date(friend.since).getDate()]).fromNow(false)} */}
                                            {moment(friend?.since).fromNow()}
                                            {/* {moment.duration(friend.since).asMinutes()} */}
                                          </i>
                                        </small>
                                      </div>
                                    </Link>
                                    <div className="add-delete-friends" >
                                      <a
                                        href="#"
                                        title=""
                                        onClick={() => {
                                          acceptFriendRequest &&
                                            acceptFriendRequest(
                                              friend._id,
                                              "header"
                                            );
                                        }}
                                      >
                                        <i className="fa fa-heart"></i>
                                      </a>
                                      <a
                                        href="/landing"
                                        title=""
                                        onClick={() => {
                                          rejectFriendRequest &&
                                            rejectFriendRequest(friend._id);
                                        }}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </a>
                                    </div>
                                  </div>

                                </li>
                              </Dropdown.Item>
                            </React.Fragment>
                          );
                        })}
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    title="Notifications"
                    as={CustomNotificationToggle}
                  />
                  <Dropdown.Menu className="dropdowns ps-container ps-theme-default ps-active-y drops-menu">
                    <Dropdown.Item disabled>
                      {!!notifications?.filter((el) => !el.seen).length && (
                        <span>
                          {notifications?.filter((el) => !el.seen).length} New
                          Notifications{" "}
                          {/* <a href="#" title="">
                            View all Notifications
                          </a> */}
                        </span>
                      )}
                      {!notifications?.filter((el) => !el.seen).length && (
                        <span> No New Notifications</span>
                      )}
                    </Dropdown.Item>
                    {notifications?.map((notification: any, index: any) => {
                      return (
                        <React.Fragment key={index}>
                          <Dropdown.Divider />
                          <Dropdown.Item style={{height:'60px'}}>
                            <div className="row">
                              <div className='col-sm-3' style={{ padding: '0px' }}>
                                <Link
                                  to={
                                    (notification.notificationObject?.type ==
                                      "friend_request_accept" || "friend_request")
                                      ? `/timeline/${notification?.noti_from_id?._id}`
                                      : `/landing/`
                                  }
                                >
                                  <figure>
                                    <img
                                      src={
                                        notification?.noti_from_id?.profile_pic ||
                                        "https://via.placeholder.com/20"
                                      }
                                      //@ts-ignore
                                      onError={(e) => {
                                        //@ts-ignore
                                        e.target.onerror = null;
                                        //@ts-ignore
                                        e.target.src =
                                          "/images/default-avatar-placeholder.png";
                                      }}
                                      alt=""
                                      className="frd-reg-img rounded-circle"
                                    />
                                  </figure>
                                </Link>
                              </div>
                              <div className="col-sm-7" style={{ padding:'0px',marginTop:'5px'}}>
                                <p className="mesg-meta-fr-dropdown-small"
                                  onClick={() => openPost(notification, index)}

                                >
                                  {notification.notification}{" "}
                                </p>
                              </div>
                              <div className="col-sm-2" style={{ padding:'0px',marginTop:'30px'}}>
                                <i style={{padding:'0.2em 0.5em',marginLeft:'-20px'}}>
                                  {moment(notification.date).fromNow(true)}
                                </i>

                              </div>
                            </div>








                            {/* </li> */}
                          </Dropdown.Item>
                        </React.Fragment>
                      );
                    })}
                    {/* <Dropdown.Item>
											View All notifications
										</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
                {
                  notificationPost &&
                  <>

                    {notifications && (
                      notifications.map((notiPost: any, index: any) => {
                        return (
                          <FvrtPostView notiIndex={index} setNotificationPost={setNotificationPost} notificationPost={notificationPost} notifications={notifications} setSelectedNotificationPost={setSelectedNotificationPost} selectedNotificationPost={selectedNotificationPost} />
                        );
                      })
                    )}
                  </>
                }

                {/* <a href="#" title="Notification" data-ripple="">
									<i className="fa fa-bell"></i><em className="bg-purple">7</em>
								</a>
								<div className="dropdowns ps-container ps-theme-default ps-active-y" data-ps-id="b989250f-eb05-d13c-48e9-a560d6040c72">
									<span>4 New Notifications <a href="#" title="">Mark all as read</a></span>
									<ul className="drops-menu">
										<li>
											<a href="notifications.html" title="">
												<figure>
													<img src="../images/resources/thumb-1.jpg" alt="" />
													<span className="status f-online"></span>
												</figure>
												<div className="mesg-meta">
													<h6>sarah Loren</h6>
													<span>commented on your new profile status</span>
													<i>2 min ago</i>
												</div>
											</a>
										</li>
										<li>
											<a href="notifications.html" title="">
												<figure>
													<img src="../images/resources/thumb-2.jpg" alt="" />
													<span className="status f-online"></span>
												</figure>
												<div className="mesg-meta">
													<h6>Jhon doe</h6>
													<span>Nicholas Grissom just became friends. Write on his wall.</span>
													<i>4 hours ago</i>
													<figure>
														<span>Today is Marina Valentine’s Birthday! wish for celebrating</span>
														<img src="/images/birthday.png" alt="" />
													</figure>
												</div>
											</a>
										</li>
										<li>
											<a href="notifications.html" title="">
												<figure>
													<img src="../images/resources/thumb-3.jpg" alt="" />
													<span className="status f-online"></span>
												</figure>
												<div className="mesg-meta">
													<h6>Andrew</h6>
													<span>commented on your photo.</span>
													<i>Sunday</i>
													<figure>
														<span>"Celebrity looks Beautiful in that outfit! We should see each"</span>
														<img src="../images/resources/admin.jpg" alt="" />
													</figure>
												</div>
											</a>
										</li>
										<li>
											<a href="notifications.html" title="">
												<figure>
													<img src="../images/resources/thumb-4.jpg" alt="" />
													<span className="status f-online"></span>
												</figure>
												<div className="mesg-meta">
													<h6>Tom cruse</h6>
													<span>nvited you to attend to his event Goo in</span>
													<i>May 19</i>
												</div>
											</a>
											<span className="tag">New</span>
										</li>
										<li>
											<a href="notifications.html" title="">
												<figure>
													<img src="../images/resources/thumb-5.jpg" alt="" />
													<span className="status f-online"></span>
												</figure>
												<div className="mesg-meta">
													<h6>Amy</h6>
													<span>Andrew Changed his profile picture. </span>
													<i>dec 18</i>
												</div>
											</a>
											<span className="tag">New</span>
										</li>
									</ul>
									<a href="notifications.html" title="" className="more-mesg">View All</a>
									<div className="ps-scrollbar-x-rail"><div className="ps-scrollbar-x"></div></div><div className="ps-scrollbar-y-rail"><div className="ps-scrollbar-y"></div></div></div> */}
              </li>
              <li>
                <Link to="/chats" title="Messages" data-ripple="">
                  <i className="fa fa-commenting"></i>
                  <em className="bg-red">{props.chats.length}</em>
                </Link>
                <div
                  className="dropdowns ps-container ps-theme-default ps-active-y"
                  data-ps-id="793f4c2c-78ed-a5f7-b1fc-8c07ea084fe5"
                >
                  <span>
                    5 New Messages{" "}
                    <a href="#" title="">
                      Mark all as read
                    </a>
                  </span>
                  <ul className="drops-menu">
                    <li>
                      <a className="show-mesg" href="#" title="">
                        <figure>
                          <img src="../images/resources/thumb-1.jpg" alt="" />
                          <span className="status f-online"></span>
                        </figure>
                        <div className="mesg-meta">
                          <h6>sarah Loren</h6>
                          <span>
                            <i className="ti-check"></i> Hi, how r u dear ...?
                          </span>
                          <i>2 min ago</i>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a className="show-mesg" href="#" title="">
                        <figure>
                          <img src="../images/resources/thumb-2.jpg" alt="" />
                          <span className="status f-offline"></span>
                        </figure>
                        <div className="mesg-meta">
                          <h6>Jhon doe</h6>
                          <span>
                            <i className="ti-check"></i> We’ll have to check
                            that at the office and see if the client is on board
                            with
                          </span>
                          <i>2 min ago</i>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a className="show-mesg" href="#" title="">
                        <figure>
                          <img src="../images/resources/thumb-3.jpg" alt="" />
                          <span className="status f-online"></span>
                        </figure>
                        <div className="mesg-meta">
                          <h6>Andrew</h6>
                          <span>
                            {" "}
                            <i className="fa fa-paperclip"></i>Hi Jack's! It’s
                            Diana, I just wanted to let you know that we have to
                            reschedule..
                          </span>
                          <i>2 min ago</i>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a className="show-mesg" href="#" title="">
                        <figure>
                          <img src="../images/resources/thumb-4.jpg" alt="" />
                          <span className="status f-offline"></span>
                        </figure>
                        <div className="mesg-meta">
                          <h6>Tom cruse</h6>
                          <span>
                            <i className="ti-check"></i> Great, I’ll see you
                            tomorrow!.
                          </span>
                          <i>2 min ago</i>
                        </div>
                      </a>
                      <span className="tag">New</span>
                    </li>
                    <li>
                      <a className="show-mesg" href="#" title="">
                        <figure>
                          <img src="../images/resources/thumb-5.jpg" alt="" />
                          <span className="status f-away"></span>
                        </figure>
                        <div className="mesg-meta">
                          <h6>Amy</h6>
                          <span>
                            <i className="fa fa-paperclip"></i> Sed ut
                            perspiciatis unde omnis iste natus error sit{" "}
                          </span>
                          <i>2 min ago</i>
                        </div>
                      </a>
                      <span className="tag">New</span>
                    </li>
                  </ul>
                  <a href="chat-messenger.html" title="" className="more-mesg">
                    View All
                  </a>
                  <div className="ps-scrollbar-x-rail">
                    <div className="ps-scrollbar-x"></div>
                  </div>
                  <div className="ps-scrollbar-y-rail">
                    <div className="ps-scrollbar-y"></div>
                  </div>
                </div>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    title="Friend Requests"
                    as={MoreOptionsToggle}
                  ></Dropdown.Toggle>

                  <Dropdown.Menu
                    className="dropdowns ps-container ps-theme-default ps-active-y drops-menu"
                    style={{ maxWidth: "10px" }}
                  >
                    <Dropdown.Item disabled>
                      <b>Help with this page</b>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to={"/forum"}>
                        <div>
                          <i className="fa fa-book"></i> Forums
                        </div>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to={`/faq`}>
                        <div>
                          <i className="fa fa-question-circle"></i> FAQs
                        </div>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <Link to={`/Careers/${user_Id}`}>
                        <div>
                          <i className="fa fa-building"></i>
                          Careers
                        </div>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item href='/privacy-policy/terms-of-services'>
                      <i className="fa fa-edit"></i>
                      Terms & policy
                    </Dropdown.Item>
                    <Dropdown.Item href='/support-help'>
                      <i className="fa fa-phone-square"></i>
                      Supports & help
                    </Dropdown.Item>
                    {/* <Dropdown.Item>
                      <i className="fa fa-map-marker"></i>
                      Contact
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <i className="fa fa-exclamation-triangle"></i>
                      Report a problem
                    </Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li>
                <Link
                  to={"/login"}
                  onClick={handleLogout}
                  className="header-icon"
                  title="Log out"
                  data-ripple=""
                >
                  <i className="fa fa-sign-out"></i>
                </Link>
              </li>
            </ul>
          </div>
          <ul className="nav-list">
            <li>
              <a className="" href="#" title="">
                <i className="fa fa-home"></i> Home Pages
              </a>
              <ul>
                <li>
                  <a href="index.html" title="">
                    Epic Much Default
                  </a>
                </li>
                <li>
                  <a href="company-landing.html" title="">
                    Company Landing
                  </a>
                </li>
                <li>
                  <a href="epicrest.html" title="">
                    Epicrest
                  </a>
                </li>
                <li>
                  <a href="redpit.html" title="">
                    Redpit
                  </a>
                </li>
                <li>
                  <a href="redpit-category.html" title="">
                    Redpit Category
                  </a>
                </li>
                <li>
                  <a href="soundnik.html" title="">
                    Soundnik
                  </a>
                </li>
                <li>
                  <a href="soundnik-detail.html" title="">
                    Soundnik Single
                  </a>
                </li>
                <li>
                  <a href="career.html" title="">
                    EpicJob
                  </a>
                </li>
                <li>
                  <a href="shop.html" title="">
                    Shop
                  </a>
                </li>
                <li>
                  <a href="classified.html" title="">
                    Classified
                  </a>
                </li>
                <li>
                  <a href="epicpoint.html" title="">
                    EpicPoint
                  </a>
                </li>
                <li>
                  <a href="epictube.html" title="">
                    EpicTube
                  </a>
                </li>
                <li>
                  <a href="chat-messenger.html" title="">
                    Messenger
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a className="" href="#" title="">
                <i className="fa fa-film"></i> EpicTube
              </a>
              <ul>
                <li>
                  <a href="epictube.html" title="">
                    EpicTube
                  </a>
                </li>
                <li>
                  <a href="epictube-detail.html" title="">
                    EpicTube single
                  </a>
                </li>
                <li>
                  <a href="epictube-category.html" title="">
                    EpicTube Category
                  </a>
                </li>
                <li>
                  <a href="epictube-channel.html" title="">
                    EpicTube Channel
                  </a>
                </li>
                <li>
                  <a href="epictube-search-result.html" title="">
                    EpicTube Search Result
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a className="" href="#" title="">
                <i className="fa fa-female"></i> EpicPoint
              </a>
              <ul>
                <li>
                  <a href="epicpoint.html" title="">
                    EpicPoint
                  </a>
                </li>
                <li>
                  <a href="epicpoint-detail.html" title="">
                    EpicPoint Detail
                  </a>
                </li>
                <li>
                  <a href="epicpoint-list.html" title="">
                    EpicPoint List style
                  </a>
                </li>
                <li>
                  <a href="epicpoint-without-baner.html" title="">
                    EpicPoint without Banner
                  </a>
                </li>
                <li>
                  <a href="epicpoint-search-result.html" title="">
                    EpicPoint Search
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a className="" href="#" title="">
                <i className="fa fa-graduation-cap"></i> EpicJob
              </a>
              <ul>
                <li>
                  <a href="career.html" title="">
                    EpicJob
                  </a>
                </li>
                <li>
                  <a href="career-detail.html" title="">
                    EpicJob Detail
                  </a>
                </li>
                <li>
                  <a href="career-search-result.html" title="">
                    Job seach page
                  </a>
                </li>
                <li>
                  <a href="social-post-detail.html" title="">
                    Social Post Detail
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a className="" href="#" title="">
                <i className="fa fa-repeat"></i> Timeline
              </a>
              <ul>
                <li>
                  <a href="timeline.html" title="">
                    Timeline
                  </a>
                </li>
                <li>
                  <a href="timeline-photos.html" title="">
                    Timeline Photos
                  </a>
                </li>
                <li>
                  <a href="timeline-videos.html" title="">
                    Timeline Videos
                  </a>
                </li>
                <li>
                  <a href="timeline-groups.html" title="">
                    Timeline Groups
                  </a>
                </li>
                <li>
                  <a href="timeline-friends.html" title="">
                    Timeline Friends
                  </a>
                </li>
                <li>
                  <a href="timeline-friends2.html" title="">
                    Timeline Friends-2
                  </a>
                </li>
                <li>
                  <a href="about.html" title="">
                    Timeline About
                  </a>
                </li>
                <li>
                  <a href="blog-posts.html" title="">
                    Timeline Blog
                  </a>
                </li>
                <li>
                  <a href="friends-birthday.html" title="">
                    Friends' Birthday
                  </a>
                </li>
                <li>
                  <a href="newsfeed.html" title="">
                    Newsfeed
                  </a>
                </li>
                <li>
                  <a href="search-result.html" title="">
                    Search Result
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a className="" href="#" title="">
                <i className="fa fa-heart"></i> Favourit Page
              </a>
              <ul>
                <li>
                  <a href="fav-page.html" title="">
                    Favourit Page
                  </a>
                </li>
                <li>
                  <a href="fav-favers.html" title="">
                    Fav Page Likers
                  </a>
                </li>
                <li>
                  <a href="fav-events.html" title="">
                    Fav Events
                  </a>
                </li>
                <li>
                  <a href="fav-event-invitations.html" title="">
                    Fav Event Invitations
                  </a>
                </li>
                <li>
                  <a href="event-calendar.html" title="">
                    Event Calendar
                  </a>
                </li>
                <li>
                  <a href="fav-page-create.html" title="">
                    Create New Page
                  </a>
                </li>
                <li>
                  <a href="price-plans.html" title="">
                    Price Plan
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a className="" href="#" title="">
                <i className="fa fa-forumbee"></i> Forum
              </a>
              <ul>
                <li>
                  <a href="forum.html" title="">
                    Forum
                  </a>
                </li>
                <li>
                  <a href="forum-create-topic.html" title="">
                    Forum Create Topic
                  </a>
                </li>
                <li>
                  <a href="forum-open-topic.html" title="">
                    Forum Open Topic
                  </a>
                </li>
                <li>
                  <a href="forums-category.html" title="">
                    Forum Category
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a className="" href="#" title="">
                <i className="fa fa-star-o"></i> Featured
              </a>
              <ul>
                <li>
                  <a href="chat-messenger.html" title="">
                    Messenger (Chatting)
                  </a>
                </li>
                <li>
                  <a href="notifications.html" title="">
                    Notifications
                  </a>
                </li>
                <li>
                  <a href="badges.html" title="">
                    Badges
                  </a>
                </li>
                <li>
                  <a href="faq.html" title="">
                    Faq's
                  </a>
                </li>
                <li>
                  <a href="contribution.html" title="">
                    Contriburion Page
                  </a>
                </li>
                <li>
                  <a href="manage-page.html" title="">
                    Manage Page
                  </a>
                </li>
                <li>
                  <a href="weather-forecast.html" title="">
                    weather-forecast
                  </a>
                </li>
                <li>
                  <a href="statistics.html" title="">
                    Statics/Analytics
                  </a>
                </li>
                <li>
                  <a href="shop-cart.html" title="">
                    Shop Cart
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a className="" href="#" title="">
                <i className="fa fa-gears"></i> Account Setting
              </a>
              <ul>
                <li>
                  <a href="setting.html" title="">
                    Setting
                  </a>
                </li>
                <li>
                  <a href="privacy.html" title="">
                    Privacy
                  </a>
                </li>
                <li>
                  <a href="support-and-help.html" title="">
                    Support &amp; Help
                  </a>
                </li>
                <li>
                  <a href="support-and-help-detail.html" title="">
                    Support Detail
                  </a>
                </li>
                <li>
                  <a href="support-and-help-search-result.html" title="">
                    Support Search
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a className="" href="#" title="">
                <i className="fa fa-lock"></i> Authentication
              </a>
              <ul>
                <li>
                  <a href="login.html" title="">
                    Login Page
                  </a>
                </li>
                <li>
                  <a href="register.html" title="">
                    Register Page
                  </a>
                </li>
                <li>
                  <a href="logout.html" title="">
                    Logout Page
                  </a>
                </li>
                <li>
                  <a href="coming-soon.html" title="">
                    Coming Soon
                  </a>
                </li>
                <li>
                  <a href="error-404.html" title="">
                    Error 404
                  </a>
                </li>
                <li>
                  <a href="error-404-2.html" title="">
                    Error 404-2
                  </a>
                </li>
                <li>
                  <a href="error-500.html" title="">
                    Error 500
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <a className="" href="#" title="">
                <i className="fa fa-wrench"></i> Tools
              </a>
              <ul>
                <li>
                  <a href="typography.html" title="">
                    Typography
                  </a>
                </li>
                <li>
                  <a href="popup-modals.html" title="">
                    Popups/Modals
                  </a>
                </li>
                <li>
                  <a href="post-versions.html" title="">
                    Post Versions
                  </a>
                </li>
                <li>
                  <a href="sliders.html" title="">
                    Sliders / Carousel
                  </a>
                </li>
                <li>
                  <a href="google-map.html" title="">
                    Google Maps
                  </a>
                </li>
                <li>
                  <a href="widgets.html" title="">
                    Widgets
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="mobileheader d-none">
          <div className="d-flex justify-content-between">
            <div
              style={{
                color: "white",
                fontSize: "24px",
                fontWeight: 500,
              }}
            >
              EPIC MUCH
            </div>
            <div>
              <ul className="setting-area mb-0">
                <li className="responsiveheader-icon">
                  <a
                    href="#javascript"
                    onClick={() => setShow(true)}
                    className="header-icon"
                    title="Home"
                    data-ripple=""
                  >
                    <img
                      className="mobileimg-responsive"
                      src="../images/menubar.png"
                      alt=""
                    />
                  </a>
                </li>
                <li className="responsiveheader-icon">
                  <Link
                    to={""}
                    className="header-icon"
                    title="Home"
                    data-ripple=""
                  >
                    <img
                      className="mobileimg-responsive"
                      src="../images/message_menu.png"
                      alt=""
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="">
            <div className="responsive-search">
              <form method="post" className="">
                <input
                  type="text"
                  className="mobile-searchbar"
                  placeholder="Search People"
                />
                <button data-ripple="">
                  <i className="ti-search"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
        {show && (
          <div id="mySidenav" className="sidenav">
            <a
              href="#javascript"
              className="closebtn"
              onClick={() => setShow(false)}
            >
              &times;
            </a>
            <a href="">
              <img
                className="menuimg-responsive"
                src="../images/settings.png"
                alt=""
              />{" "}
              Settings
            </a>
            <a href="">
              <img
                className="menuimg-responsive"
                src="../images/faqs.png"
                alt=""
              />{" "}
              FAQs
            </a>
            <a href="">
              <img
                className="menuimg-responsive"
                src="../images/community.png"
                alt=""
              />{" "}
              Community & Forum
            </a>
            <a href="">
              <img
                className="menuimg-responsive"
                src="../images/career.png"
                alt=""
              />{" "}
              Careers
            </a>
            <a href="">
              <img
                className="menuimg-responsive"
                src="../images/terms.png"
                alt=""
              />{" "}
              Terms & Policy
            </a>
            <a href="">
              <img
                className="menuimg-responsive"
                src="../images/support.png"
                alt=""
              />{" "}
              Support & Help
            </a>
            <a href="">
              <img
                className="menuimg-responsive"
                src="../images/contact.png"
                alt=""
              />{" "}
              Contact
            </a>
            <a href="">
              <img
                className="menuimg-responsive"
                src="../images/report.png"
                alt=""
              />{" "}
              Report a Problem
            </a>
          </div>
        )}

        {/*toaster.show && <div
                aria-live="polite"
                aria-atomic="true"
                style={{
                    position: 'relative',
                    minHeight: '100px',
                }}
                >
                <Toast 
                    show={toaster.show}
                    autohide={true}
                    style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: toaster.status === 'error' ? 'red' : 'green',
                    color: '#fff',
                    margin: '1rem',
                    padding: '.5rem 1rem'
                    }}
                >
                    <Toast.Body>{toaster.message}</Toast.Body>
                </Toast>
				</div>*/}
      </>
    );
  }
};

export default Header;
