import {
    GET_CHETS,GET_CHETS_FAIL,GET_CHETS_SUCESS,
    SAVE_WEB_SOCKET,ADD_NEW_CHAT,GET_CHAT_MESSAGE_SUCESS,
    JOIN_ROOM,SEND_MESSAGE_SUCESS,
    NEW_MESSAGE_SOCKET,
    REMOVE_ALL_CHATS,
    SEND_TYPING, RECIEVE_TYPING, RECIEVE_TYPING_SUCESS, RECIEVE_TYPING_FAIL,
    ONLINE_USERS, ONLINE_USERS_SUCCESS, ONLINE_USERS_FAIL,
    OFFLINE_USERS, OFFLINE_USERS_SUCCESS, OFFLINE_USERS_FAIL,
    LEAVE_ROOM, LEAVE_ROOM_SUCCESS, LEAVE_ROOM_FAIL,
    ROOM_LEAVED, ROOM_LEAVED_SUCCESS, ROOM_LEAVED_FAIL
}
 from "../actions/chat";
 import Swal from "sweetalert2";
const initialstate = {
    loading:false,
    chats:[],
    socket:null,
    activeroom:null,
    newchat:null
}
const chatReducer = (state: any = initialstate, action: any) => {
    switch (action.type) {
      case GET_CHETS:
          return{
              ...state,
              loading:true
          }
      
      case GET_CHETS_SUCESS:
        return{
            ...state,
            loading:false,
            chats:action.payload
        }
    case GET_CHETS_FAIL:
        return{
            ...state,
            loading:false,
            chats:[]
        }
    case ADD_NEW_CHAT:
        return{
            ...state,
            newchat:action.payload  
        }
    case REMOVE_ALL_CHATS:
        return{
            ...state,
            chats:[]
        }
    case JOIN_ROOM:
        return{
            ...state,
            activeroom:action.payload
        }  
        
    case SEND_TYPING:
        return{
            ...state,
            typingevent: action.payload
        }
    case RECIEVE_TYPING:
        return{
            ...state,
            typing: false
        }    
    case RECIEVE_TYPING_SUCESS:
        return{
            ...state,
            typing:true
    }
    case RECIEVE_TYPING_FAIL:
        return{
            ...state,
            typing:false
        }
    case ONLINE_USERS:
        return{
            ...state,
            loading:true
        }
    
    case ONLINE_USERS_SUCCESS:
        return{
            ...state,
            loading:false,
            online_users:action.payload
        }
    case ONLINE_USERS_FAIL:
        return{
            ...state,
            loading:false,
            online_users:[]
        }
    case OFFLINE_USERS:
        return{
            ...state,
            loading:true
        }
    
    case OFFLINE_USERS_SUCCESS:
        return{
            ...state,
            loading:false,
            offline_users:action.payload
        }
    case OFFLINE_USERS_FAIL:
        return{
            ...state,
            loading:false,
            offline_users:[]
        }
    case SAVE_WEB_SOCKET:
        return{
            ...state,
            socket:action.payload.socket,
        } 
    case GET_CHAT_MESSAGE_SUCESS:
        var newchats = state.chats.map((item)=>{
            if(item._id===action.payload.chatid){
                return {...item,allMessages:action.payload.data}
            }
            return item;
        })
        return{
            ...state,
            chats:newchats,
        }  
    case SEND_MESSAGE_SUCESS:
        var newchats = state.chats.map((item)=>{
            if(item._id===action.payload.chatid){
                return {...item,allMessages:[...item.allMessages,action.payload.data]}
            }
            return item;
        })
        return{
            ...state,
            chats:newchats,
        }  
    case NEW_MESSAGE_SOCKET:
        var newchats = state.chats.map((item)=>{
            if(item._id===action.payload.RooomId){
                return {...item,allMessages:[...item.allMessages,{messages: action.payload?.messages[0], _id: action.payload?._id}]}
            }
            return item;
        })
        return{
            ...state,
            chats:newchats,
        }  
    case LEAVE_ROOM:
        return{
            ...state,
            loading:true
        }    
    case LEAVE_ROOM_SUCCESS:
        return{
            ...state,
            loading:false,
        }
    case LEAVE_ROOM_FAIL:
        return{
            ...state,
            loading:false,
        }
    case ROOM_LEAVED:
        return{
            ...state,
            loading:true
        }
    
    case ROOM_LEAVED_SUCCESS:
        return{
            ...state,
            loading:false,
        }
    case ROOM_LEAVED_FAIL:
        return{
            ...state,
            loading:false,
        }
        default: return state
        
    }
    
      
}

export default chatReducer;