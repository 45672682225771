import Axios from 'axios';
import Swal from "sweetalert2";
import { getUserInfo } from "../actions/user";
import { GET_POST_SUCCESS } from './post';
export const CREATE_PAGE_REQUEST = "CREATE_PAGE_REQUEST";
export const CREATE_PAGE_SUCCESS = "CREATE_PAGE_SUCCESS";
export const CREATE_PAGE_ERROR = "CREATE_PAGE_ERROR";
export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_ERROR = "CREATE_EVENT_ERROR";
export const CREATE_EVENT_EDIT_REQUEST='CREATE_EVENT_EDIT_REQUEST';
export const EDIT_EVENT_SUCCESS= 'EDIT_EVENT_SUCCESS';
export const EDIT_EVENT_ERROR= 'EDIT_EVENT_ERROR';
export const UPDATE_PAGE_REQUEST = "UPDATE_PAGE_REQUEST";
export const UPDATE_PAGE_SUCCESS = "UPDATE_PAGE_SUCCESS";
export const UPDATE_PAGE_ERROR = "UPDATE_PAGE_ERROR";
export const CREATE_PAGE_POST_REQUEST = "CREATE_PAGE_POST_REQUEST";
export const CREATE_PAGE_POST_SUCCESS = "CREATE_PAGE_POST_SUCCESS";
export const CREATE_PAGE_POST_ERROR = "CREATE_PAGE_POST_ERROR";
export const REPORT_PAGE_SUCCESS = "REPORT_PAGE_SUCCESS";
export const REPORT_PAGE_ERROR = "REPORT_PAGE_ERROR";
export const REPORT_PAGE_REQUEST = "REPORT_PAGE_REQUEST";

// export const ADD_LIKE_REQUEST = "ADD_LIKE_REQUEST";
// export const ADD_LIKE_SUCCESS = "ADD_LIKE_SUCCESS";
// export const ADD_LIKE_ERROR = "ADD_LIKE_ERROR";
export const GET_PAGE_REQUEST = "GET_PAGE_REQUEST";
export const GET_PAGE_SUCCESS = "GET_PAGE_SUCCESS";
export const GET_PAGE_ERROR = "GET_PAGE_ERROR";
export const GET_PAGE_DETAILS_REQUEST = "GET_PAGE_DETAILS_REQUEST";
export const GET_PAGE_DETAILS_SUCCESS = "GET_PAGE_DETAILS_SUCCESS";
export const GET_PAGE_DETAILS_ERROR = "GET_PAGE_DETAILS_ERROR";

export const GET_EVENT_BY_ID_SUCEESS= "GET_EVENT_BY_ID_SUCEES";
export const GET_EVENT_BY_ID_ERROR = "GET_PAGE_DETAILS_ERROR";

export const GET_PAGE_FOLLOWERS_REQUEST = "GET_PAGE_FOLLOWERS_REQUEST";
export const GET_PAGE_FOLLOWERS_SUCCESS = "GET_PAGE_FOLLOWERS_SUCCESS";
export const GET_PAGE_FOLLOWERS_ERROR = "GET_PAGE_FOLLOWERS_ERROR";
export const GET_PAGE_EVENTS_REQUEST = "GET_PAGE_EVENTS_REQUEST";
export const GET_PAGE_EVENTS_SUCCESS = "GET_PAGE_EVENTS_SUCCESS";
export const GET_PAGE_EVENTS_ERROR = "GET_PAGE_EVENTS_ERROR";
export const GET_PAGE_POSTS_REQUEST = "GET_PAGE_POSTS_REQUEST";
export const GET_PAGE_POSTS_SUCCESS = "GET_PAGE_POSTS_SUCCESS";
export const GET_PAGE_POSTS_ERROR = "GET_PAGE_POSTS_ERROR";
export const GET_SUGGESTED_PAGES_REQUEST = "GET_SUGGESTED_PAGES_REQUEST";
export const GET_SUGGESTED_PAGES_SUCCESS = "GET_SUGGESTED_PAGES_SUCCESS";
export const GET_SUGGESTED_PAGES_ERROR = "GET_SUGGESTED_PAGES_ERROR";
export const ADD_PAGE_LIKE_REQUEST = "ADD_PAGE_LIKE_REQUEST";
export const ADD_PAGE_LIKE_SUCCESS = "ADD_PAGE_LIKE_SUCCESS";
export const ADD_PAGE_LIKE_ERROR = "ADD_PAGE_LIKE_ERROR";
export const UNLIKE_PAGE_REQUEST = "UNLIKE_PAGE_REQUEST";
export const UNLIKE_PAGE_SUCCESS = "UNLIKE_PAGE_SUCCESS";
export const UNLIKE_PAGE_ERROR = "UNLIKE_PAGE_ERROR";
export const FOLLOW_PAGE_REQUEST = "FOLLOW_PAGE_REQUEST";
export const FOLLOW_PAGE_SUCCESS = "FOLLOW_PAGE_SUCCESS";
export const FOLLOW_PAGE_ERROR = "FOLLOW_PAGE_ERROR";
export const UNFOLLOW_PAGE_REQUEST = "UNFOLLOW_PAGE_REQUEST";
export const UNFOLLOW_PAGE_SUCCESS = "UNFOLLOW_PAGE_SUCCESS";
export const UNFOLLOW_PAGE_ERROR = "UNFOLLOW_PAGE_ERROR";
export const BLOCK_PAGE_REQUEST = "BLOCK_PAGE_REQUEST";
export const BLOCK_PAGE_SUCCESS = "BLOCK_PAGE_SUCCESS";
export const BLOCK_PAGE_ERROR = "BLOCK_PAGE_ERROR";
export const UPDATE_PAGE_COVER_PHOTO_REQUEST =
  "UPDATE_PAGE_COVER_PHOTO_REQUEST";
export const UPDATE_PAGE_COVER_PHOTO_ERROR = "UPDATE_PAGE_COVER_PHOTO_ERROR";
export const UPDATE_PAGE_COVER_PHOTO_SUCCESS =
  "UPDATE_PAGE_COVER_PHOTO_SUCCESS";
export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS='DELETE_EVENT_SUCCESS';
export const DELELTE_EVENT_ERROR='DELELTE_EVENT_ERROR';
export const SEND_INVITE_REQUEST = "SEND_INVITE_REQUEST";
export const SEND_INVITE_SUCCESS = "SEND_INVITE_SUCCESS";
export const SEND_INVITE_ERROR = "SEND_INVITE_ERROR";

export const GET_INVITE_SUCCESS = "GET_INVITE_SUCCESS";
export const GET_INVITE_ERROR = "GET_INVITE_ERROR";

// export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
// export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
// export const ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";
// export const EDIT_POST_REQUEST = "EDIT_POST_REQUEST";
// export const EDIT_POST_SUCCESS = "EDIT_POST_SUCCESS";
// export const EDIT_POST_ERROR = "EDIT_POST_ERROR";
// export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
// export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
// export const DELETE_POST_ERROR = "DELETE_POST_ERROR";
// export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
// export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
// export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";
// export const UNLIKE_REQUEST = "UNLIKE_REQUEST";
// export const UNLIKE_SUCCESS = "UNLIKE_SUCCESS";
// export const UNLIKE_ERROR = "UNLIKE_ERROR";
// export const LIKE_COMMENT_SUCCESS = "LIKE_COMMENT_SUCCESS";
// export const LIKE_COMMENT_REQUEST = "LIKE_COMMENT_REQUEST";
// export const LIKE_COMMENT_ERROR = "LIKE_COMMENT_ERROR";
// export const UNLIKE_COMMENT_REQUEST = "UNLIKE_COMMENT_REQUEST";
// export const UNLIKE_COMMENT_SUCCESS = "UNLIKE_COMMENT_SUCCESS";
// export const UNLIKE_COMMENT_ERROR = "UNLIKE_COMMENT_ERROR";
// export const GET_POST_TOTAL = "GET_POST_TOTAL";
// export const GET_POST_TOTAL_SUCCESS = "GET_POST_TOTAL_SUCCESS";
// export const GET_POST_TOTAL_ERROR = "GET_POST_TOTAL_ERROR";
// export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
// export const GET_ALL_COMMENTS_SUCCESS = "GET_ALL_COMMENTS_SUCCESS";
// export const GET_ALL_COMMENTS_ERROR = "GET_ALL_COMMENTS_ERROR";

export const createPage =
  (data: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: CREATE_PAGE_REQUEST, payload: data });
    const pageCreationURL = `${process.env.REACT_APP_API_URL}/fav-page/create`;
    // const pageCreationURL = `${process.env.REACT_APP_API_URL}/patreon/create`;
    console.log(data);
    console.log("Sending Request to :", pageCreationURL)
    try {
      const response: any = await Axios.post(
        pageCreationURL,
        data
      );
      const realData = await response.data;
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Page Created", "", "success");

      return dispatch({
        type: CREATE_PAGE_SUCCESS,
        payload: realData.data,
      });
    } catch (err) {
      console.log(err);
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Page creation failed!", "Please reload and try again.", "error");
      return dispatch({ type: CREATE_PAGE_ERROR, payload: err });
    }
  };

export const reportPage =
  (data: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: REPORT_PAGE_REQUEST, payload: data });
    try {
      console.log("working");
      const response: any = await Axios.post(
        `${process.env.REACT_APP_API_URL}/fav-page/report-a-page`,
        data
      );
      const realData = await response.data;
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Report Page", "", "success");

      return dispatch({
        type: REPORT_PAGE_SUCCESS,
        payload: realData.data,
      });
    } catch (err) {
      console.log(err);
      Swal.mixin({
        toast: true,
        position: "top-end",
        
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Page Report failed!", "Please reload and try again.", "error");
      return dispatch({ type: REPORT_PAGE_ERROR, payload: err });
    }
  };

export const createEvent =
  (data: any,pageId) => async (dispatch: any, getState: any) => {
    console.log('createData', data)
    await dispatch({ type: CREATE_EVENT_REQUEST, payload: data });
    try {
      const response: any = await Axios.post(
        `${process.env.REACT_APP_API_URL}/fav-page/create-event`,
        data
      );
      const realData = await response.data;
      if (realData.status) {
        Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire("Event Created", "", "success");
        dispatch(getPageEvents(pageId,""));
        return dispatch({
          type:CREATE_EVENT_SUCCESS,
          payload: realData.data
        });
      } else {
        Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire(
          "Event creation failed!",
          "Please reload and try again.",
          "error"
        );
        return dispatch({
          type: CREATE_EVENT_ERROR,
          payload: response.message,
        });
      }
    } catch (err) {
      console.log(err);
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire(
        "Event creation failed!",
        "Please reload and try again.",
        "error"
      );
      return dispatch({ type: CREATE_EVENT_ERROR, payload: err });
    }
  };

  export const getPageEvents =
  (id: string, query: string) => async (dispatch: any, getState: any) => {
    console.log('get id',id)
    const state = getState();
    await dispatch({ type: GET_PAGE_EVENTS_REQUEST, payload: id });

    console.log(query);
    
    const url = `${process.env.REACT_APP_API_URL}/fav-page/event-list/${id}?offset=0&search=${query}`;
    try {
      const response: any = await Axios.get(url);
      const data = await response.data;

      return dispatch({
        type: GET_PAGE_EVENTS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_PAGE_EVENTS_ERROR, payload: err });
    }
  };

  export const getEventById =
  (id: string) => async (dispatch: any, getState: any) => {
    const state = getState();
    await dispatch({ type: GET_PAGE_DETAILS_REQUEST, payload: id });
    const url = `${process.env.REACT_APP_API_URL}/fav-page/event/${id}`;
    try {
      const response: any = await Axios.get(url);
      const data = await response.data;
      console.log( "get event by id", data);

      return dispatch({
        type: GET_EVENT_BY_ID_SUCEESS,
        payload: data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_EVENT_BY_ID_ERROR, payload: err });
    }
  };

  export const deleteEvents =
  (id: string,pageId) => async (dispatch: any, getState: any) => {
    await dispatch({ type:  DELETE_EVENT_REQUEST, payload: id });
    try {
      const response: any = await Axios.delete(
        `${process.env.REACT_APP_API_URL}/fav-page/event/${id}`,
          
      );
        Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
         
        }).fire("Event Deleted", "", "success");
        dispatch(getPageEvents(pageId,""));
        return dispatch({
          type: DELETE_EVENT_SUCCESS,
          payload: {data:response.data,id}
        });
      } 
        catch (err) {
          return dispatch({ type: DELELTE_EVENT_ERROR, payload: err });
        }
    } 

export const updatePageDetails =
  (data: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: UPDATE_PAGE_REQUEST, payload: data });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/fav-page/update-page-details`,
        data
      );
      const realData = await response.data;
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Page updated!", "", "success");

      await dispatch(getPageDetails(data.page_id));
    } catch (err) {
      console.log(err);
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Page creation failed!", "Please reload and try again.", "error");
      return dispatch({ type: UPDATE_PAGE_ERROR, payload: err });
    }
  };

export const createPagePost =
  (data: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: CREATE_PAGE_POST_REQUEST, payload: data });
    try {
      const response: any = await Axios.post(
        `${process.env.REACT_APP_API_URL}/fav-page/create-post`,
        data
      );
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Posted", "", "success");

      dispatch(getPagePosts(data.get("page_id")));
      return dispatch({
        type: CREATE_PAGE_POST_SUCCESS,
        payload: response.data.data,
      });
    } catch (err) {
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Upload Failed!", "Please reload and try again.", "error");
      return dispatch({ type: CREATE_PAGE_POST_ERROR, payload: err });
    }
  };

export const getFavoritePages =
  (id: string, offset = 0) =>
  async (dispatch: any, getState: any) => {
    console.log("GEtting Fav Pages")
    const state = getState();
    await dispatch({ type: GET_PAGE_REQUEST, payload: id });
    const url = `${process.env.REACT_APP_API_URL}/fav-page/get-page?offset=${offset}`;
    console.log('url', url)
    try {
      const response: any = await Axios.get(url);
      const data = await response.data;

      // let modData;
      // if (state.page) {
      //   // response.data.data = state.page.concat(
      //   response.data.data = state.page?.data?.concat(
      //     response.data.data
      //   );
      // }
      console.log("Favorite Pages:", data.data)
      return dispatch({
        type: GET_PAGE_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_PAGE_ERROR, payload: err });
    }
  };
  // export const getPatreonPages =
  // (id: string) =>
  // async (dispatch: any, getState: any) => {
  //   console.log("GEtting Patreon Pages")
  //   const state = getState();
  //   await dispatch({ type: GET_PATREON_PAGE_REQUEST, payload: id });
  //   const url = `${process.env.REACT_APP_API_URL}/patreon/get-page?offset=0`;
  //   console.log("Patreon URL:", url)
  //   try {
  //     const response: any = await Axios.get(url);
  //     const data = await response.data;
  //     console.log("PAtreons:", data)
  //     return dispatch({
  //       type: GET_PAGE_SUCCESS,
  //       payload: data.data,
  //     });
  //   } catch (err) {
  //     return dispatch({ type: GET_PAGE_ERROR, payload: err });
  //   }
  // };
export const getPageDetails =
  (id: string) => async (dispatch: any, getState: any) => {
    const state = getState();
    await dispatch({ type: GET_PAGE_DETAILS_REQUEST, payload: id });
    const url = `${process.env.REACT_APP_API_URL}/fav-page/get-page-details/${id}`;
    try {
      const response: any = await Axios.get(url);
      const data = await response.data;

      console.log(data);

      return dispatch({
        type: GET_PAGE_DETAILS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_PAGE_DETAILS_ERROR, payload: err });
    }
  };
export const getPagePosts =
  (id: string) => async (dispatch: any, getState: any) => {
    const state = getState();
    await dispatch({ type: GET_PAGE_POSTS_REQUEST, payload: id });
    // const url = isPatreonPage ? `${process.env.REACT_APP_API_URL}/fav-page/get-post/${id}?offset=0` : `${process.env.REACT_APP_API_URL}/patreon/get-post/${id}?offset=0`;
    const url = `${process.env.REACT_APP_API_URL}/fav-page/get-post/${id}?offset=0` 
    try {
      const response: any = await Axios.get(url);
      const data = await response.data;
      console.log('fvrtPageRes', data)
       dispatch({
        type: GET_POST_SUCCESS,
        payload:response.data.data,
      });
      return dispatch({
        type: GET_PAGE_POSTS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_PAGE_POSTS_ERROR, payload: err });
    }
  };


export const getPageFollowers =
  (id: string) => async (dispatch: any, getState: any) => {
    const state = getState();
    await dispatch({ type: GET_PAGE_FOLLOWERS_REQUEST, payload: id });
    const url = `${process.env.REACT_APP_API_URL}/fav-page/get-follower/${id}?offset=0`;
    try {
      const response: any = await Axios.get(url);
      const data = await response.data;

      return dispatch({
        type: GET_PAGE_FOLLOWERS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_PAGE_FOLLOWERS_ERROR, payload: err });
    }
  };

export const getSuggestedPages =
  (pageNumber = 1) =>
  async (dispatch: any) => {
    await dispatch({ type: GET_SUGGESTED_PAGES_REQUEST });
    try {
      const response: any = await Axios.get(
        `${process.env.REACT_APP_API_URL}/fav-page/get-suggestion-pages?offset=0`
      );
      return dispatch({
        type: GET_SUGGESTED_PAGES_SUCCESS,
        payload: response.data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_SUGGESTED_PAGES_ERROR, payload: err });
    }
  };
export const addPageLike =
  (id: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: ADD_PAGE_LIKE_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/fav-page/like/${id}/like`
      );
      await dispatch(getPageDetails(id));
      return dispatch({
        type: ADD_PAGE_LIKE_SUCCESS,
        payload: { data: response.data.data, postId: id },
      });
    } catch (err) {
      return dispatch({ type: ADD_PAGE_LIKE_ERROR, payload: err });
    }
  };

export const removePageLike =
  (id: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: UNLIKE_PAGE_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/fav-page/like/${id}/unlike`
      );
      return dispatch({
        type: UNLIKE_PAGE_SUCCESS,
        payload: { data: response.data.data, postId: id },
      });
    } catch (err) {
      return dispatch({ type: UNLIKE_PAGE_ERROR, payload: err });
    }
  };
export const followPage =
  (id: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: FOLLOW_PAGE_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/fav-page/follow/${id}/follow`
      );
      return dispatch({
        type: FOLLOW_PAGE_SUCCESS,
        payload: { data: response.data.data, postId: id },
      });
    } catch (err) {
      return dispatch({ type: FOLLOW_PAGE_ERROR, payload: err });
    }
  };
export const unfollowPage =
  (id: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: UNFOLLOW_PAGE_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/fav-page/follow/${id}/unfollow`
      );
      return dispatch({
        type: UNFOLLOW_PAGE_SUCCESS,
        payload: { data: response.data.data, postId: id },
      });
    } catch (err) {
      return dispatch({ type: UNFOLLOW_PAGE_ERROR, payload: err });
    }
  };
export const blockPage =
  (id: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: BLOCK_PAGE_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/fav-page/block/${id}/block`
      );
      return dispatch({
        type: BLOCK_PAGE_SUCCESS,
        payload: { data: response.data.data, postId: id },
      });
    } catch (err) {
      return dispatch({ type: BLOCK_PAGE_ERROR, payload: err });
    }
  };

// export const addComment =
//     (id: string, data: any) => async (dispatch: any, getState: any) => {
//         await dispatch({ type: ADD_COMMENT_REQUEST, payload: id });
//         try {
//             const response: any = await Axios.put(
//                 `${process.env.REACT_APP_API_URL}/posts/comment/${id}`,
//                 { comment: data }
//             );
//             return dispatch({
//                 type: ADD_COMMENT_SUCCESS,
//                 payload: { postId: id, data: response.data.data },
//             });
//         } catch (err) {
//             return dispatch({ type: ADD_COMMENT_ERROR, payload: err });
//         }
//     };

// export const getPost = (id: string) => async (dispatch: any, getState: any) => {
//     const state = getState();
//     // await dispatch({ type: GET_POST_REQUEST, payload: id });
//     const url = `${process.env.REACT_APP_API_URL}/posts/post/${id}`;
//     try {
//         const response: any = await Axios.get(url); //page &&
//         if (state.post.data) {
//             response.data.data.posts = state.post.data.concat(
//                 response.data.data.posts
//             );
//         }
//         return dispatch({
//             type: GET_POST_SUCCESS,
//             payload: response.data.data,
//         });
//     } catch (err) {
//         return dispatch({ type: GET_POST_ERROR, payload: err });
//     }
// };

// export const editPost =
//     (postId: string, data: any) => async (dispatch: any, getState: any) => {
//         await dispatch({ type: EDIT_POST_REQUEST, payload: postId });
//         try {
//             const response: any = await Axios.put(
//                 `${process.env.REACT_APP_API_URL}/posts/post/${postId}`,
//                 data
//             );
//             return dispatch({
//                 type: EDIT_POST_SUCCESS,
//                 payload: response.data,
//             });
//         } catch (err) {
//             return dispatch({ type: EDIT_POST_ERROR, payload: err });
//         }
//     };

// export const deletePost =
//     (postId: string) => async (dispatch: any, getState: any) => {
//         await dispatch({ type: DELETE_POST_REQUEST, payload: postId });
//         try {
//             const response: any = await Axios.delete(
//                 `${process.env.REACT_APP_API_URL}/posts/${postId}`
//             );
//             return dispatch({
//                 type: DELETE_POST_SUCCESS,
//                 payload: { data: response.data, postId },
//             });
//         } catch (err) {
//             return dispatch({ type: DELETE_POST_ERROR, payload: err });
//         }
//     };

// export const deleteComment =
//     (postId: string, commentId: string) =>
//         async (dispatch: any, getState: any) => {
//             await dispatch({ type: DELETE_COMMENT_REQUEST, payload: postId });
//             try {
//                 const response: any = await Axios.delete(
//                     `${process.env.REACT_APP_API_URL}/posts/${postId}/comment/${commentId}`
//                 );
//                 Swal.mixin({
//                     toast: true,
//                     position: "top-end",
//                     showConfirmButton: false,
//                     timer: 3000,
//                     timerProgressBar: true,
//                     didOpen: (toast) => {
//                         toast.addEventListener("mouseenter", Swal.stopTimer);
//                         toast.addEventListener("mouseleave", Swal.resumeTimer);
//                     },
//                 }).fire("Post Deleted!", "", "success");
//                 return dispatch({
//                     type: DELETE_COMMENT_SUCCESS,
//                     payload: { postId, commentId },
//                 });
//             } catch (err) {
//                 return dispatch({ type: DELETE_COMMENT_ERROR, payload: err });
//             }
//         };

// export const likeComment =
//     (id: string) => async (dispatch: any, getState: any) => {
//         await dispatch({ type: LIKE_COMMENT_REQUEST, payload: id });
//         try {
//             const response: any = await Axios.put(
//                 `${process.env.REACT_APP_API_URL}/posts/comment/like/${id}`
//             );
//             return dispatch({
//                 type: LIKE_COMMENT_SUCCESS,
//                 payload: { data: response.data, commentId: id },
//             });
//         } catch (err) {
//             return dispatch({ type: LIKE_COMMENT_ERROR, payload: err });
//         }
//     };

// export const unlikeComment =
//     (id: string) => async (dispatch: any, getState: any) => {
//         await dispatch({ type: UNLIKE_COMMENT_REQUEST, payload: id });
//         try {
//             const response: any = await Axios.put(
//                 `${process.env.REACT_APP_API_URL}/posts/comment/unlike/${id}`
//             );
//             return dispatch({
//                 type: UNLIKE_COMMENT_SUCCESS,
//                 payload: { data: response.data, commentId: id },
//             });
//         } catch (err) {
//             return dispatch({ type: UNLIKE_COMMENT_ERROR, payload: err });
//         }
//     };

// export const getTotalPosts =
//     (id: string) => async (dispatch: any, getState: any) => {
//         await dispatch({ type: GET_POST_TOTAL, payload: id });
//         try {
//             const response: any = await Axios.get(
//                 `${process.env.REACT_APP_API_URL}/profile/${id}/getTopCount`
//             );
//             return dispatch({
//                 type: GET_POST_TOTAL_SUCCESS,
//                 payload: { data: response.data },
//             });
//         } catch (err) {
//             return dispatch({ type: GET_POST_TOTAL_ERROR, payload: err });
//         }
//     };

// export const getAllComments =
//     (id: string) => async (dispatch: any, getState: any) => {
//         await dispatch({ type: GET_ALL_COMMENTS, payload: id });
//         try {
//             const response: any = await Axios.get(
//                 `${process.env.REACT_APP_API_URL}/posts/comment/${id}?pageNumber=1`
//             );
//             let reversedComments = {
//                 ...response.data.data,
//                 comments: response.data.data.comments.reverse(),
//             };
//             return dispatch({
//                 type: GET_ALL_COMMENTS_SUCCESS,
//                 payload: { data: reversedComments, postId: id },
//             });
//         } catch (err) {
//             return dispatch({ type: GET_ALL_COMMENTS_ERROR, payload: err });
//         }
//     };


/////////    upload photo..........................   ///////////
export const updatePageMedia =
  (id: string, coverphoto: any, type: string) =>
  async (dispatch: any, getState: any) => {
    await dispatch({ type: UPDATE_PAGE_COVER_PHOTO_REQUEST });
    try {
      const formData = new FormData();
      formData.append("photo", coverphoto);
      formData.append("page_id", id);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let url = "";
      if (type == "cover") {
        url = `${process.env.REACT_APP_API_URL}/fav-page/upload-cover-photo`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/fav-page/upload-profile-photo`;
      }

      const response: any = await Axios.put(url, formData, config);
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Looks great, Updated!", "", "success");
      dispatch(getPageDetails(id));

      return dispatch({
        type: UPDATE_PAGE_COVER_PHOTO_SUCCESS,
        payload: response.data,
      });
    } catch (err: any) {
      return dispatch({ type: UPDATE_PAGE_COVER_PHOTO_ERROR, payload: err });
    }
  };






  

export const updateProfilePageMedia =
  (id: string, coverphoto: any, type: string) =>
  async (dispatch: any, getState: any) => {
    await dispatch({ type: UPDATE_PAGE_COVER_PHOTO_REQUEST });
    try {
      const formData = new FormData();
      formData.append("file", coverphoto);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      let url = "";
      if (type == "cover") {
        url = `${process.env.REACT_APP_API_URL}/profile/upload/cover`;
      } else {
        url = `${process.env.REACT_APP_API_URL}/profile/upload/dp`;
      }

      const response: any = await Axios.put(url, formData, config);
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Looks great, Updated!", "", "success");
      dispatch(getPageDetails(id));
      dispatch(getUserInfo(id));

      return dispatch({
        type: UPDATE_PAGE_COVER_PHOTO_SUCCESS,
        payload: response.data,
      });
    } catch (err: any) {
      return dispatch({ type: UPDATE_PAGE_COVER_PHOTO_ERROR, payload: err });
    }
  };


export const sendEventInvite = (data:any) => 
  async (dispatch: any, getState: any) => {
    const state = getState();
    await dispatch({ type: SEND_INVITE_REQUEST, payload: data });
    const url = `${process.env.REACT_APP_API_URL}/fav-page/sent-invite`;
    try {
      const response: any = await Axios.post(url, data);
      const resp = await response.data;

      if(resp.status){
        return dispatch({
          type: SEND_INVITE_SUCCESS,
          payload: resp.data,
        });
      }
      else{
        return dispatch({
          type: SEND_INVITE_SUCCESS,
          payload: resp,
        });
      }
      
    } catch (err) {
      return dispatch({ type: SEND_INVITE_ERROR, payload: err });
    }
}

export const getInviteList = (data:any) => 
  async (dispatch: any, getState: any) => {
    const state = getState();
    const url = `${process.env.REACT_APP_API_URL}/fav-page/invite-user-list/${data.page_id}/${data.event_id}` 
    try {
      const response: any = await Axios.get(url);
      const data = await response.data;

      console.log(data.data);
      return dispatch({
        type: GET_INVITE_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_INVITE_ERROR, payload: err });
    }
}