export const SELECT_AVATAR = "SELECT_AVATAR";
export const SAVE_AVATAR = "SAVE_AVATAR";

export const selectAvatar = (data: any) => async (dispatch: any) => {
  await dispatch({ type: SELECT_AVATAR, payload: data });
};

export const saveAvatar = (data: any) => async (dispatch: any) => {
  await dispatch({ type: SAVE_AVATAR, payload: data });
};
