import { connect } from "react-redux";
import Header from "./header";
import {
  getUserInfo,
  getAllFriends,
  getAllFriendRequest,
  getAllNotification,
  acceptFriendRequest,
  rejectFriendRequest,
  USER_LOGOUT,
  CLEAR_USER_STATE,
  getChats
} from "./../../actions";

const mapStateToProps = (state: any) => {
  return {
    notifications: state.user.notifications,
    friendRequests: state.friend.requests,
    isDeleted: state.settings.isDeleted,
    toaster: state.toaster,
    chats:state.chat.chats
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // dispatching actions returned by action creators
    getAllFriends: (profileId: any) => dispatch(getAllFriends(profileId)),
    getUserInfo: (profileId: any) => dispatch(getUserInfo(profileId)),
    getAllFriendRequest: () => dispatch(getAllFriendRequest()),
    getAllNotification: (profileId: any) =>
      dispatch(getAllNotification(profileId)),
    acceptFriendRequest: (id: string, page: string) =>
      dispatch(acceptFriendRequest(id, page)),
    rejectFriendRequest: (id: string) => dispatch(rejectFriendRequest(id)),
    logout: () => dispatch({ type: USER_LOGOUT }),
    clearUserState: () => dispatch({ type: CLEAR_USER_STATE }),
    getChats: (page?: any) => dispatch(getChats(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
