export class AuthService {

  isAuthenticated = () => {
    let access_token = localStorage.getItem('access_token');
    return access_token;
  }
  user_id = () => {
    let user_id = localStorage.getItem('user_id');
    return user_id;
  }
}


export default new AuthService();
