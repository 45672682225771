import React, { useEffect, useState } from "react";
import "./FvrtView.scss";
import axios from "axios";
import moment from "moment";
import { useHistory, Link, useParams } from "react-router-dom";

const FvrtPostView = (props) => {
  const { image, setSingleDataView, selectedViewImage, notificationPost, notifications, notiIndex,
    selectedNotificationPost, setNotificationPost } = props

  console.log('proping', image)
  const [index, setIndex] = useState(selectedViewImage?.index);
  const [filterData, setFilterData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [notiPostInd, setNotiPostInd] = useState(selectedNotificationPost?.index);


  console.log('ppp', selectedViewImage)

  const [post, setPost] = useState({
    author_id: {
      f_name: "",
      l_name: "",
      profile_pic: "",
      user_id: "",
      _id: "",
    },
    image: [{ URL: "", mediaType: "" }],
    date: "",
    likes: [{ _id: "" }],
    comments: [
      {
        author: { f_name: "", l_name: "", profile_pic: "" },
        comment: "",
        date: "",
        _id: "",
        likes: [
          {
            _id: "",
            f_name: "",
            l_name: "",
            user_id: "",
            profile_pic: "",
          },
        ],
      },
    ],
  });

  const userId = localStorage.getItem("user_id");
  const handlePreviousPost = () => {
    console.log("previous..")
    if (index !== 0) {
      setIndex(index - 1)
    }
  };

  const handleNextPost = () => {
    console.log("next..")
    if (index !== image?.length - 1) {
      setIndex(index + 1)
    }
  };
  useEffect(() => {
    const filterData = image?.filter((img: any, i: number) => i === index)
    setFilterData(filterData)
  }, [index])



  console.log('filterData', filterData)





  return (
    <div className="PostView">
      <div className="row no-gutters">
        <div className="col-lg-12 col-md-8 rounded" >
          <div className="image-view">
            <div
              onClick={() => {
                (!notificationPost === true) ? setSingleDataView(false) :

                  setNotificationPost(false)
              }}
            >
              <img src="../images/x_close.png" className="x_close" />
              {
                (!notificationPost === true) ? <>
                  <a href="/landing">
                    <img src="/images/logo.png" alt="" className="v_logo" />
                  </a>
                </> : null
              }


            </div>

            <div
              className="image_view"
              style={{ display: "flex", gap: "2rem", flexWrap: "wrap" }}
            >

              {
                (notificationPost === true) ?
                  notifications.filter((data, indx) =>(data.notificationObject.type === 'post' || data.notificationObject.type === 'page_invite') && indx === notiPostInd).map((notificationP: any, idx) => {
                    console.log('notificationP', notificationP)
                    return (
                      <div key={idx}>
                        { notificationP.notificationObject.type !== 'page_invite'?
                          (notificationP.notificationObject.postdata.post.description && notificationP.notificationObject.postdata.post.image.length===0) ?
                          <><h5>{notificationP.notificationObject.postdata.post.description.slice(3,-4)}</h5></>:
                          (notificationP.notificationObject.postdata.post.image[0].mediaType === "image") ?
                            <img src={notificationP.notificationObject.postdata.post.image[0].URL} alt="post" className="rounded" />
                            :

                            <video width="auto" height="auto" controls={true}>
                              <source src={notificationP?.notificationObject.postdata.post.image[0].URL} type="video/mp4" />
                            </video>:<h3>{notificationP.noti_from_id.f_name} {notificationP.noti_from_id.l_name} Sent you a invite for page event</h3>
                        }
                      </div>
                    );
                  })
                  :
                  filterData &&
                  filterData.map((posts: any, idx) => {
                    console.log('posts',filterData)
                    return (<div key={idx}>
                      {
                        (posts?.mediaType === "image") ?
                          <img src={posts.URL} alt="post" className="rounded" />
                          :
                          <video width="auto" height="auto" controls={true}>
                            <source src={posts?.URL} type="video/mp4" />
                          </video>
                      }
                    </div>
                    );
                  })

              }
            </div>
          </div>
          {
            (!notificationPost === true) ?
              <>
                <button
                  disabled={index === 0 ? true : false}
                  onClick={handlePreviousPost}
                  className="prev-post-btn"

                >
                  {" "}
                  <i className="fa fa-caret-left"></i>{" "}
                </button>
                <button
                  disabled={index === image?.length - 1 ? true : false}
                  className="next-post-btn"
                  onClick={handleNextPost}

                >
                  <i className="fa fa-caret-right"></i>
                </button>
              </> : null
          }

        </div>

      </div>
    </div>
  );
};

export default FvrtPostView;
