import { combineReducers } from "redux";
import type { RootState } from "./state";
import avatarReducer from "./avatar";
import userReducer from "./user";
import toasterReducer from "./toaster";
import postReducer from "./post";
import pageReducer from "./page";
import patreonPageReducer from "./patreonPage";
import friendReducer from "./friend";
import settingsReducer from "./settings";
import chatReducers from "./chats"

export type { RootState };

const appReducer = combineReducers<RootState>({
  avatar: avatarReducer,
  user: userReducer,
  toaster: toasterReducer,
  post: postReducer,
  page: pageReducer,
  patreonPage: patreonPageReducer,
  friend: friendReducer,
  settings: settingsReducer,
  chat:chatReducers
});

export const rootReducer = (state: any, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
