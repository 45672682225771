import {
    UPDATE_TOASTER,
    REMOVE_TOASTER
} from "../actions/toaster";

const initialState = {show: false, message: "", status: ""}
//const initialState = {show: true, message: "test toaster", status: "success"}
  
const toasterReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_TOASTER:
            return {
                ...state,
                ...action.payload
            };
        case REMOVE_TOASTER:
            return initialState
        default:
            return state;
    }
};
  
export default toasterReducer;
