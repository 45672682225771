import {
    GET_ALL_FRIEND_REQUEST_SUCCESS,
    GET_RECOMMENDED_USERS_SUCCESS,
    SEND_FRIEND_REQUEST_SUCCESS
} from "../actions/friend";

const initialData = {
    requests: [],
    recommended: []
}
  
const friendReducer = (state = initialData, action: any) => {
    switch (action.type) {
        case 'GET_ALL_FRIEND_REQUEST_SUCCESS':
            return {
                ...state,
                requests: action.payload
            };
        case 'GET_RECOMMENDED_USERS_SUCCESS':
            return {
                ...state,
                recommended: action.payload
            }
        case 'SEND_FRIEND_REQUEST_SUCCESS':
            if (action.payload.page === 'home') {
                let recommended = state.recommended.map((friend: any) => {
                    if (action.payload.receiver_id === friend._id) {
                        friend.requested = true;
                    }
                    return friend
                })
                return {
                    ...state,
                    recommended
                }
            } else {
                return {
                    ...state
                }
            }
            case 'REJECT_FRIEND_REQUEST_SUCCESS':
                        
                    let recommended = state.recommended.map((friend: any) => {
                        if (action.payload.receiver_id === friend._id) {
                            friend.requested = false;
                        }
                        return friend
                    })
                    return {
                        ...state,
                        recommended
                    }
        default:
            return state;
    }
};
  
export default friendReducer;