import Axios from 'axios';
import Swal from "sweetalert2";

export const CREATE_POST_REQUEST = "CREATE_POST_REQUEST";
export const CREATE_POST_SUCCESS = "CREATE_POST_SUCCESS";
export const CREATE_POST_ERROR = "CREATE_POST_ERROR";
export const ADD_LIKE_REQUEST = "ADD_LIKE_REQUEST";
export const ADD_LIKE_SUCCESS = "ADD_LIKE_SUCCESS";
export const ADD_LIKE_ERROR = "ADD_LIKE_ERROR";
export const GET_POST_REQUEST = "GET_POST_REQUEST";
export const GET_POST_SUCCESS = "GET_POST_SUCCESS";
export const GET_POST_ERROR = "GET_POST_ERROR";
export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_ERROR = "ADD_COMMENT_ERROR";
export const EDIT_POST_REQUEST = "EDIT_POST_REQUEST";
export const EDIT_POST_SUCCESS = "EDIT_POST_SUCCESS";
export const EDIT_POST_ERROR = "EDIT_POST_ERROR";
export const DELETE_POST_REQUEST = "DELETE_POST_REQUEST";
export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_ERROR = "DELETE_POST_ERROR";
export const DELETE_COMMENT_REQUEST = "DELETE_COMMENT_REQUEST";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";
export const UNLIKE_REQUEST = "UNLIKE_REQUEST";
export const UNLIKE_SUCCESS = "UNLIKE_SUCCESS";
export const UNLIKE_ERROR = "UNLIKE_ERROR";
export const LIKE_COMMENT_SUCCESS = "LIKE_COMMENT_SUCCESS";
export const LIKE_COMMENT_REQUEST = "LIKE_COMMENT_REQUEST";
export const LIKE_COMMENT_ERROR = "LIKE_COMMENT_ERROR";
export const UNLIKE_COMMENT_REQUEST = "UNLIKE_COMMENT_REQUEST";
export const UNLIKE_COMMENT_SUCCESS = "UNLIKE_COMMENT_SUCCESS";
export const UNLIKE_COMMENT_ERROR = "UNLIKE_COMMENT_ERROR";
export const GET_POST_TOTAL = "GET_POST_TOTAL";
export const GET_POST_TOTAL_SUCCESS = "GET_POST_TOTAL_SUCCESS";
export const GET_POST_TOTAL_ERROR = "GET_POST_TOTAL_ERROR";
export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const GET_ALL_COMMENTS_SUCCESS = "GET_ALL_COMMENTS_SUCCESS";
export const GET_ALL_COMMENTS_ERROR = "GET_ALL_COMMENTS_ERROR";
export const SET_PROGRESS_PERCENT="SET_PROGRESS_PERCENT";

export const createPost =
  (data: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: CREATE_POST_REQUEST, payload: data });
    try {
      const response: any = await Axios.post(
        `${process.env.REACT_APP_API_URL}/posts/create`,
        data
      );
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Posting", "", "success");
      return dispatch({
        type: CREATE_POST_SUCCESS,
        payload: response.data.data,
      });
    } catch (err) {
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Upload Failed!", "Please reload and try again.", "error");
      return dispatch({ type: CREATE_POST_ERROR, payload: err });
    }
  };

export const addLike = (id: string) => async (dispatch: any, getState: any) => {
  await dispatch({ type: ADD_LIKE_REQUEST, payload: id });
  try {
    const response: any = await Axios.put(
      `${process.env.REACT_APP_API_URL}/posts/like/${id}`
    );
    console.log('addlikefirst', response)
    return dispatch({
      type: ADD_LIKE_SUCCESS,
      payload: { data: response.data.data, postId: id },
    });
  } catch (err) {
    return dispatch({ type: ADD_LIKE_ERROR, payload: err });
  }
};

export const removeLike =
  (id: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: UNLIKE_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/posts/dislike/${id}`
      );
      return dispatch({
        type: UNLIKE_SUCCESS,
        payload: { data: response.data.data, postId: id },
      });
    } catch (err) {
      return dispatch({ type: UNLIKE_ERROR, payload: err });
    }
  };



export const getPosts =
  (id: string, page, type = "home") =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    await dispatch({ type: GET_POST_REQUEST, payload: id });
    const url =
      type == "home"
        ? `${process.env.REACT_APP_API_URL}/home?pageNumber=${page + 1}`
        : `${
            process.env.REACT_APP_API_URL
          }/posts/${id}?type=Profile&pageNumber=${page + 1}`;
    try {
      const response: any = await Axios.get(url);
      console.log('landingres', response)
      if (page && state.post.data) {
        response.data.data.posts = state.post.data.concat(
          response.data.data.posts
        );
      }
      return dispatch({
        type: GET_POST_SUCCESS,
        payload: response.data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_POST_ERROR, payload: err });
    }
  };

export const getPost = (id: string) => async (dispatch: any, getState: any) => {
  const state = getState();
  // await dispatch({ type: GET_POST_REQUEST, payload: id });
  const url = `${process.env.REACT_APP_API_URL}/posts/post/${id}`;
  try {
    const response: any = await Axios.get(url); //page &&
    if (state.post.data) {
      response.data.data.posts = state.post.data.concat(
        response.data.data.posts
      );
    }
    return dispatch({
      type: GET_POST_SUCCESS,
      payload: response.data.data,
    });
  } catch (err) {
    return dispatch({ type: GET_POST_ERROR, payload: err });
  }
};

export const editPost =
  (postId: string, data: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: EDIT_POST_REQUEST, payload: postId });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/posts/post/${postId}`,
        data
      );
      return dispatch({
        type: EDIT_POST_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      return dispatch({ type: EDIT_POST_ERROR, payload: err });
    }
  };

export const deletePost =
  (postId: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: DELETE_POST_REQUEST, payload: postId });
    try {
      const response: any = await Axios.delete(
        `${process.env.REACT_APP_API_URL}/posts/${postId}`
      );
      return dispatch({
        type: DELETE_POST_SUCCESS,
        payload: { data: response.data, postId },
      });
    } catch (err) {
      return dispatch({ type: DELETE_POST_ERROR, payload: err });
    }
  };

export const deleteComment =
  (postId: string, commentId: string) =>
  async (dispatch: any, getState: any) => {
    await dispatch({ type: DELETE_COMMENT_REQUEST, payload: postId });
    try {
      const response: any = await Axios.delete(
        `${process.env.REACT_APP_API_URL}/posts/${postId}/comment/${commentId}`
      );
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Comment Deleted!", "", "success"); 
      return dispatch({
        type: DELETE_COMMENT_SUCCESS,
        payload: { postId, commentId },
      });
    } catch (err) {
      return dispatch({ type: DELETE_COMMENT_ERROR, payload: err });
    }
  };

export const likeComment =
  (id: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: LIKE_COMMENT_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/posts/comment/like/${id}`
      );
      return dispatch({
        type: LIKE_COMMENT_SUCCESS,
        payload: { data: response.data, commentId: id },
      });
    } catch (err) {
      return dispatch({ type: LIKE_COMMENT_ERROR, payload: err });
    }
  };

export const unlikeComment =
  (id: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: UNLIKE_COMMENT_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/posts/comment/unlike/${id}`
      );
      return dispatch({
        type: UNLIKE_COMMENT_SUCCESS,
        payload: { data: response.data, commentId: id },
      });
    } catch (err) {
      return dispatch({ type: UNLIKE_COMMENT_ERROR, payload: err });
    }
  };

export const getTotalPosts =
  (id: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: GET_POST_TOTAL, payload: id });
    try {
      const response: any = await Axios.get(
        `${process.env.REACT_APP_API_URL}/profile/${id}/getTopCount`
      );
      return dispatch({
        type: GET_POST_TOTAL_SUCCESS,
        payload: { data: response.data },
      });
    } catch (err) {
      return dispatch({ type: GET_POST_TOTAL_ERROR, payload: err });
    }
  };

  export const addComment =
  (id: string, data: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: ADD_COMMENT_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/posts/comment/${id}`,
        { comment: data }
      );
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("commenting", "", "success");
      dispatch(getAllComments(id));
      
      return dispatch({
        type: ADD_COMMENT_SUCCESS,
        payload: { postId: id, data: response.data.data },
      });
    } catch (err) {
      return dispatch({ type: ADD_COMMENT_ERROR, payload: err });
    }
  };

export const getAllComments =
  (id: string) => async (dispatch: any, getState: any) => {
    await dispatch({ type: GET_ALL_COMMENTS, payload: id });
    try {
      const response: any = await Axios.get(
        `${process.env.REACT_APP_API_URL}/posts/comment/${id}?pageNumber=1`
      );
      console.log('allcomntresponse', response)
      let reversedComments = {
        ...response.data.data,
        comments: response.data.data.comments,
      };
      return dispatch({
        type: GET_ALL_COMMENTS_SUCCESS,
        payload: { data: reversedComments, postId: id },
      });
    } catch (err) {
      return dispatch({ type: GET_ALL_COMMENTS_ERROR, payload: err });
    }
  };
