import {
    UPDATE_PRIVACY_REQUEST,
    UPDATE_PRIVACY_ERROR,
    UPDATE_PRIVACY_SUCCESS,
    UPDATE_PRIVACY_FIELD,
    UPDATE_PRIVACY_FIELD_ERROR,
    UPDATE_PRIVACY_FIELD_SUCCESS,
    DELETE_ACCOUNT_SUCCESS,
    GET_SETTINGS_SUCCESS
} from "../actions/settings";
import Swal from "sweetalert2";
const settingsReducer = (state: any = {}, action: any) => {
    switch (action.type) {
        case UPDATE_PRIVACY_REQUEST:
            return {
                ...state
            }
        case DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isDeleted: true
            }
        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            }
        case UPDATE_PRIVACY_SUCCESS:
            {
                Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                }).fire("Updated!", "", "success");
                return {
                    ...state,
                    data: {
                        ...state.data,
                        isPrivate: action.payload
                    }
                }
            }
        case UPDATE_PRIVACY_FIELD_SUCCESS:
            {
                Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener("mouseenter", Swal.stopTimer);
                        toast.addEventListener("mouseleave", Swal.resumeTimer);
                    },
                }).fire("Updated!", "", "success");
                return {
                    ...state,
                    data: {
                        ...state.data,
                        friends_visibility: action.payload,
                        isPrivate: false
                    }
                }
            }
        default:
            return state;
    }
};

export default settingsReducer;
