import {
  CREATE_PAGE_ERROR,
  CREATE_PAGE_REQUEST,
  CREATE_PAGE_SUCCESS,
  CREATE_EVENT_ERROR,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  UPDATE_PAGE_ERROR,
  UPDATE_PAGE_REQUEST,
  UPDATE_PAGE_SUCCESS,
  CREATE_EVENT_EDIT_REQUEST,
  EDIT_EVENT_SUCCESS,
  EDIT_EVENT_ERROR,
  // ADD_COMMENT_ERROR,
  // ADD_COMMENT_REQUEST,
  // ADD_COMMENT_SUCCESS,
  ADD_PAGE_LIKE_ERROR,
  ADD_PAGE_LIKE_REQUEST,
  ADD_PAGE_LIKE_SUCCESS,
  UNLIKE_PAGE_ERROR,
  UNLIKE_PAGE_REQUEST,
  UNLIKE_PAGE_SUCCESS,
  FOLLOW_PAGE_ERROR,
  FOLLOW_PAGE_REQUEST,
  FOLLOW_PAGE_SUCCESS,
  UNFOLLOW_PAGE_ERROR,
  UNFOLLOW_PAGE_REQUEST,
  UNFOLLOW_PAGE_SUCCESS,
  GET_PAGE_REQUEST,
  GET_PAGE_SUCCESS,
  GET_PAGE_ERROR,
  GET_PAGE_DETAILS_REQUEST,
  GET_PAGE_DETAILS_SUCCESS,
  GET_PAGE_DETAILS_ERROR,
  GET_PAGE_FOLLOWERS_REQUEST,
  GET_PAGE_FOLLOWERS_SUCCESS,
  GET_PAGE_FOLLOWERS_ERROR,
  UPDATE_PAGE_COVER_PHOTO_REQUEST,
  UPDATE_PAGE_COVER_PHOTO_SUCCESS,
  UPDATE_PAGE_COVER_PHOTO_ERROR,
  GET_SUGGESTED_PAGES_REQUEST,
  GET_SUGGESTED_PAGES_SUCCESS,
  GET_SUGGESTED_PAGES_ERROR,
  GET_PAGE_EVENTS_REQUEST,
  GET_PAGE_EVENTS_SUCCESS,
  GET_PAGE_EVENTS_ERROR,
  GET_PAGE_POSTS_REQUEST,
  GET_PAGE_POSTS_SUCCESS,
  GET_PAGE_POSTS_ERROR,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  DELELTE_EVENT_ERROR,
  SEND_INVITE_REQUEST,
  SEND_INVITE_SUCCESS,
  SEND_INVITE_ERROR,
  
  GET_INVITE_SUCCESS,
  GET_INVITE_ERROR
  // DELETE_PAGE_SUCCESS,
  // DELETE_COMMENT_SUCCESS,
  // UNLIKE_SUCCESS,
  // LIKE_COMMENT_SUCCESS,
  // UNLIKE_COMMENT_SUCCESS,
  // GET_PAGE_TOTAL_SUCCESS,
  // GET_ALL_COMMENTS_SUCCESS
} from "../actions/page";

const pageReducer = (state: any = {}, action: any) => {
  console.log(state);
  
  let data;
  switch (action.type) {
    case CREATE_PAGE_REQUEST:
    case CREATE_EVENT_EDIT_REQUEST:
    case GET_PAGE_REQUEST:
    case GET_PAGE_DETAILS_REQUEST:
    case GET_PAGE_FOLLOWERS_REQUEST:
    case UPDATE_PAGE_COVER_PHOTO_REQUEST:
    case GET_SUGGESTED_PAGES_REQUEST:
    case GET_PAGE_EVENTS_REQUEST:
    case GET_PAGE_POSTS_REQUEST:
    case UPDATE_PAGE_REQUEST:
    case CREATE_EVENT_REQUEST:
    case ADD_PAGE_LIKE_REQUEST:
    case FOLLOW_PAGE_REQUEST:
    case UNFOLLOW_PAGE_REQUEST:
    case UNLIKE_PAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_PAGE_SUCCESS:
      data = state.data;
      // data.unshift(action.payload);
      return {
        ...state,
        data,
        isLoading: false,
        isSuccess: true,
      };
    case CREATE_EVENT_SUCCESS:
      data = state.data;
      // data.unshift(action.payload);
      return {
        ...state,
        data,
        isLoading: false,
        isSuccess: true,
      };
    case CREATE_PAGE_ERROR:
    case GET_PAGE_ERROR:
    case GET_PAGE_DETAILS_ERROR:
    case GET_PAGE_FOLLOWERS_ERROR:
    case UPDATE_PAGE_COVER_PHOTO_ERROR:
    case GET_SUGGESTED_PAGES_ERROR:
    case GET_PAGE_POSTS_ERROR:
    case GET_PAGE_EVENTS_ERROR:
    case EDIT_EVENT_ERROR:
    case UPDATE_PAGE_ERROR:
      return {
        ...state,
        isLoading: false,
        err: action.payload,
      };
    case GET_PAGE_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload,
        isLoading: false,
      };
    case EDIT_EVENT_SUCCESS:
      return {
        ...state,
      }
    case GET_PAGE_SUCCESS:
      
      return {
        ...state,
        list: action.payload,
        isLoading: false,
      };
    case UPDATE_PAGE_COVER_PHOTO_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_PAGE_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case UPDATE_PAGE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case GET_PAGE_FOLLOWERS_SUCCESS:
      return {
        ...state,
        followers: action.payload,
        isLoading: false,
      };
    case GET_SUGGESTED_PAGES_SUCCESS:
      return {
        ...state,
        suggested: action.payload,
        isLoading: false,
      };
    case ADD_PAGE_LIKE_SUCCESS:
      state.data.isLike = true;
      state.data.likes += 1;
      return {
        ...state,
        isLoading: false,
      };
    case UNLIKE_PAGE_SUCCESS:
      state.data.isLike = false;
      state.data.likes -= 1;
      return {
        ...state,
        isLoading: false,
      };
    case FOLLOW_PAGE_SUCCESS:
      state.data.isFollow = true;
      state.data.followers += 1;
      return {
        ...state,
        isLoading: false,
      };
    case UNFOLLOW_PAGE_SUCCESS:
      state.data.isFollow = false;
      state.data.followers -= 1;
      return {
        ...state,
        isLoading: false,
      };
    case GET_PAGE_POSTS_SUCCESS:
      return {
        ...state,
        posts: action.payload,
      };
    case SEND_INVITE_REQUEST:
      return {
        ...state,
        invite: action.payload,
      };
    case SEND_INVITE_SUCCESS:
    case SEND_INVITE_ERROR:
    
    case GET_INVITE_SUCCESS:
      return {
        ...state,
        invited_followers: action.payload,
      };
    case GET_INVITE_ERROR:
    // case UNLIKE_SUCCESS:
    //     data = state.data.map((val: any) => {
    //         if (val._id === action.payload.postId) {
    //             val.likes -= 1;
    //             val.liked = false;
    //         }
    //         return val;
    //     })
    //     return {
    //         ...state,
    //         data
    //     }
    // case LIKE_COMMENT_SUCCESS:
    //     data = state.data.map((val: any) => {
    //         val.comments.map((comment: any) => {
    //             if (comment._id === action.payload.commentId) {
    //                 comment.likes.push({ _id: localStorage.getItem('user_id') });
    //                 comment.liked = true;
    //             }
    //         })
    //         return val;
    //     })
    //     return {
    //         ...state,
    //         data
    //     }
    // case UNLIKE_COMMENT_SUCCESS:
    //     data = state.data.map((val: any) => {
    //         val.comments.map((comment: any) => {
    //             if (comment._id === action.payload.commentId) {
    //                 comment.likes = comment.likes.filter((like) => like._id !== localStorage.getItem('user_id'));
    //                 comment.liked = false;
    //             }
    //         })
    //         return val;
    //     })
    //     return {
    //         ...state,
    //         data
    //     }
    // case ADD_COMMENT_SUCCESS:
    //     data = state.data.map((val: any) => {
    //         if (val._id === action.payload.postId) {
    //             if (val.commentsCount) val.commentsCount += 1;
    //             else val.commentsCount = 1;
    //             if (val.comments && Array.isArray(val.comments) && action.payload.data && action.payload.data.comment) val.comments.push(action.payload.data);
    //         }
    //         return val;
    //     })
    //     return {
    //         ...state,
    //         data
    //     }
    // case DELETE_PAGE_SUCCESS:
    //     data = state.data.filter((val: any) => {
    //         return val._id !== action.payload.postId
    //     })
    //     return {
    //         ...state,
    //         count: state.count - 1,
    //         data
    //     }
    // case DELETE_COMMENT_SUCCESS:
    //     data = state.data.filter((val: any) => {
    //         if (val._id === action.payload.postId) {
    //             val.comments = val.comments.filter((comment: any) => {
    //                 return comment._id !== action.payload.commentId;
    //             })
    //         }
    //         return true;
    //     })
    //     return {
    //         ...state,
    //         data
    //     }
    // case GET_PAGE_TOTAL_SUCCESS:
    //     return {
    //         ...state,
    //         total: action.payload.data
    //     }
    // case GET_ALL_COMMENTS_SUCCESS:
    //     data = state.data.map((val: any) => {
    //         if (val._id === action.payload.postId) {
    //             val.comments = action.payload.data.comments;
    //         }
    //         return val;
    //     })
    //     return {
    //         ...state,
    //         data
    //     }
    default:
      return state;
  }
};

export default pageReducer;
