import Axios from 'axios';
import Swal from "sweetalert2";
// https://epic.flowzcluster.tk/api/forum/get-reply/63908544f351e70021bef1a0?offset=0
export const CREATE_FORUM_TOPIC_REQUEST = "CREATE_FORUM_TOPIC_REQUEST";
export const CREATE_FORUM_TOPIC_SUCCESS = "CREATE_FORUM_TOPIC_SUCCESS";
export const CREATE_FORUM_TOPIC_ERROR = "CREATE_FORUM_TOPIC_ERROR";

export const createForumTopic =
  (data: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: CREATE_FORUM_TOPIC_REQUEST, payload: data });
    try {
      const response: any = await Axios.post(
        `${process.env.REACT_APP_API_URL}/forum/create-forum`,
        data
      );
      if (response.data.status)
        Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire("Created", "", "success");
      else
        Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        }).fire("Failed!", response?.data?.message, "error");
      return dispatch({
        type: CREATE_FORUM_TOPIC_SUCCESS,
        payload: response.data.data,
      });
    } catch (err) {
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Failed!", "Please reload and try again.", "error");
      return dispatch({ type: CREATE_FORUM_TOPIC_ERROR, payload: err });
    }
  };
