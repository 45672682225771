import React, { useState, useEffect } from "react";
import "./ForumStatistics.scss";
import PropTypes from "./types";
import axios from "axios";
import { Link } from "react-router-dom";
const ForumStatistics: React.FC<any> = (props) => {
  const userInfo = props.userInfo;
  const [stats, setStats] = useState({
    forum: 0,
    reply: 0,
    tags: 0,
    topic: 0,
    users: 0,
  });
  const [recentForums, setRecentForums] = useState([]);
  useEffect(() => {
    const fetchStats = async () => {
      const response: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/forum/get-forum-statistics`
      );
      const response1: any = await axios.get(
        `${process.env.REACT_APP_API_URL}/forum/get-recent-forum`
      );
      setStats(await response.data.data);
      setRecentForums(await response1.data.data);
    };
    fetchStats();
  }, []);

  return (
    <div className="ForumStatistics">
      <div className="widget profile__widget">
        <div className="your-page d-flex">
          <figure>
            <Link
              to={`/timeline/${props.userInfo ? props.userInfo._id : ""}`}
              title=""
            >
              <img
                src={userInfo?.profile_pic}
                alt=""
                className="profile-image"
              />
            </Link>
          </figure>
          <div className="page-meta align-self-center">
            <Link
              to={`/timeline/${props.userInfo ? props.userInfo._id : ""}`}
              title=""
            >
              {[userInfo?.f_name, userInfo?.l_name].join(" ")}
            </Link>
            <Link
              to={`/settings/${props.userInfo ? props.userInfo._id : ""}/edit`}
              href="#"
              title=""
              // className="underline mr-2 d-block user_name "
            >
              {props.userInfo?.location?.value || "Location not added"}
            </Link>
          </div>                                                                                  
          {/* <div className="more-post-optns align-self-center">
            <span className="profile-vertical-menu"></span>
          </div> */}
        </div>
      </div>

      {/* Forum Staticssss */}
      {/* <div className="forum_part" style={{border:'1px solid red'}}>
        <div className="">
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <div className="d-flex">
              <p className="forum-header">Forum Statistics</p>
              <div className="green_dot"></div>
            </div>
            <span className="vertical_lines"></span>
          </div>
          <div className="">
            <div className="statistics-content">
              <p>
                Forums <span className="noti_cir">{stats.forum}</span>
              </p>
              <p>
                Registered Users <span className="noti_cir">{stats.users}</span>
              </p>
              <p>
                Topics <span className="noti_cir">{stats.topic}</span>
              </p>
              <p>
                Replies <span className="noti_cir">{stats.reply}</span>
              </p>
              <p>
                Topic Tags <span className="noti_cir">{stats.tags}</span>
              </p>
            </div>
          </div>
        </div>
      </div> */}
      <div className="forum_part">
        <div className="">
          <div className="d-flex" style={{ justifyContent: "space-between" }}>
            <div className="d-flex">
              <p className="forum-header">Recent Activity</p>
            </div>
          </div>
          {recentForums.map((forum: any) => {
            console.log('forum',forum);
            return (
              <div className="activity_section" key={forum._id}>
                <div className="activity-content">
                  <div className="forum_activity_details">
                    {forum?.subcategory_id?.subcategory_name ||
                      "Not coming from backend"}
                  </div>
                  <span> {new Date(forum.created_at).toDateString()}</span>
                  <p>{`${forum?.added_by?.f_name??''} ${forum?.added_by?.l_name??''}`}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ForumStatistics;
