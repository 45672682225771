import React from "react";
import ForumSlider from "../../components/ForumSlider";
import Header from "../../pages/header";
import "./ForumPosts.scss";
// import ForumSearch from "../../components/ForumSearch";
import ForumStatistics from "../../components/ForumStatistics/ForumStatistics";
import ForumDetails from "../../components/ForumDetails/ForumDetails";
import { Col, Row } from "react-bootstrap";
const ForumPosts = (props) => {
  // console.log(props.match.params.id);
  return (
    <div className="ForumPosts">
      <Header />
      <ForumSlider page="forum-posts" />
      <div className="container-fluid">
        <Row className="">
          <Col className="" xl={9} lg={9} md={12}>
            {/* <ForumSearch page="forum-posts" /> */}
            <ForumDetails page="forum-posts" {...props} />
          </Col>
          <Col className="" xl={3} lg={3} md={12}>
            <ForumStatistics {...props} page="forum-posts" />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ForumPosts;
