import Axios from 'axios';
import { getUserInfo, getAllFriends } from "./";
import { updateToaster } from "./toaster";
import Swal from "sweetalert2";


export const SEND_FRIEND_REQUEST = "SEND_FRIEND_REQUEST";
export const SEND_FRIEND_REQUEST_SUCCESS = "SEND_FRIEND_REQUEST_SUCCESS";
export const SEND_FRIEND_REQUEST_ERROR = "SEND_FRIEND_REQUEST_ERROR";
export const REMOVE_FRIEND_REQUEST = "REMOVE_FRIEND_REQUEST";
export const REMOVE_FRIEND_SUCCESS = "REMOVE_FRIEND_SUCCESS";
export const REMOVE_FRIEND_ERROR = "REMOVE_FRIEND_ERROR";
export const BLOCK_FRIEND_REQUEST = "BLOCK_FRIEND_REQUEST";
export const BLOCK_FRIEND_SUCCESS = "BLOCK_FRIEND_SUCCESS";
export const BLOCK_FRIEND_ERROR = "BLOCK_FRIEND_ERROR";
export const UNBLOCK_FRIEND_REQUEST = "UNBLOCK_FRIEND_REQUEST";
export const UNBLOCK_FRIEND_SUCCESS = "UNBLOCK_FRIEND_SUCCESS";
export const UNBLOCK_FRIEND_ERROR = "UNBLOCK_FRIEND_ERROR";
export const REJECT_FRIEND_REQUEST = "REJECT_FRIEND_REQUEST";
export const REJECT_FRIEND_REQUEST_SUCCESS = "REJECT_FRIEND_REQUEST_SUCCESS";
export const REJECT_FRIEND_REQUEST_ERROR = "REJECT_FRIEND_REQUEST_ERROR";
export const ACCEPT_FRIEND_REQUEST = "ACCEPT_FRIEND_REQUEST";
export const ACCEPT_FRIEND_REQUEST_SUCCESS = "ACCEPT_FRIEND_REQUEST_SUCCESS";
export const ACCEPT_FRIEND_REQUEST_ERROR = "ACCEPT_FRIEND_REQUEST_ERROR";
export const GET_ALL_FRIEND_REQUEST = "GET_ALL_FRIEND_REQUEST";
export const GET_ALL_FRIEND_REQUEST_SUCCESS = "GET_ALL_FRIEND_REQUEST_SUCCESS";
export const GET_ALL_FRIEND_REQUEST_ERROR = "GET_ALL_FRIEND_REQUEST_ERROR";
export const GET_RECOMMENDED_USERS = "GET_RECOMMENDED_USERS";
export const GET_RECOMMENDED_USERS_SUCCESS = "GET_RECOMMENDED_USERS_SUCCESS";
export const GET_RECOMMENDED_USERS_ERROR = "GET_RECOMMENDED_USERS_ERROR";

export const sendFriendRequest =
  (receiver_id: any, page = "") =>
  async (dispatch: any, getState: any) => {
    await dispatch({ type: SEND_FRIEND_REQUEST, payload: receiver_id });
    try {
      const response: any = await Axios.post(
        `${process.env.REACT_APP_API_URL}/friendrequest`,
        { receiver_id }
      );
      if (page !== "home") dispatch(getUserInfo(receiver_id));
      const toaster = {
        status: "success",
        message: "Request successfully sent....",
        show: true,
      };
      await dispatch(updateToaster(toaster));
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Friend Request Sent!", "", "success");
      return dispatch({
        type: SEND_FRIEND_REQUEST_SUCCESS,
        payload: { data: response.data, page, receiver_id },
      });
    } catch (err) {
      return dispatch({ type: SEND_FRIEND_REQUEST_ERROR, payload: err });
    }
  };

export const removeFriend =
  (id: any) => async (dispatch: any, getState: any) => {
    console.log('working')
    console.log('id', id)
    await dispatch({ type: REMOVE_FRIEND_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/profile/unfollow/${id}`
      );
      console.log('response', response)
      const state = getState();
      console.log('state', state)
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire(
        "unFollow!","success"
      );
      dispatch(getAllFriends(state.user.data._id));
      dispatch(getUserInfo(state.user.data._id));
      return dispatch({
        type: REMOVE_FRIEND_SUCCESS,
        payload: response.data,
      });
      
    } catch (err) {
      return dispatch({ type: REMOVE_FRIEND_ERROR, payload: err });
    }
  };


export const blockFriend =
  (id: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: BLOCK_FRIEND_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/block/${id}`
      );
      const state = getState();
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire(
        "Person Blocked!",
        "You can unblock from privacy settings",
        "success"
      );
      dispatch(getAllFriends(state.user.data._id));
      dispatch(getUserInfo(state.user.data._id));
      return dispatch({
        type: BLOCK_FRIEND_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      return dispatch({ type: BLOCK_FRIEND_ERROR, payload: err });
    }
  };
export const unblockFriend =
  (id: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: UNBLOCK_FRIEND_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/unblock/${id}`
      );
      const state = getState();
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire(
        "Person Unblocked!",
        "Their profile can be viewed now.",
        "success"
      );
      dispatch(getAllFriends(state.user.data._id));
      return dispatch({
        type: UNBLOCK_FRIEND_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      return dispatch({ type: UNBLOCK_FRIEND_ERROR, payload: err });
    }
  };

export const rejectFriendRequest =
  (id: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: REJECT_FRIEND_REQUEST, payload: id });
    try {
      const response: any = await Axios.delete(
        `${process.env.REACT_APP_API_URL}/friendrequest/${id}`
      );
      dispatch(getUserInfo(id));
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Rejecting!", "", "success");
      return dispatch({
        type: REJECT_FRIEND_REQUEST_SUCCESS,
        payload: { data: response.data, receiver_id: id },
      });
    } catch (err) {
      return dispatch({ type: REJECT_FRIEND_REQUEST_ERROR, payload: err });
    }
  };

export const acceptFriendRequest =
  (id: any, page = "profile") =>
  async (dispatch: any, getState: any) => {
    await dispatch({ type: ACCEPT_FRIEND_REQUEST, payload: id });
    try {
      const response: any = await Axios.put(
        `${process.env.REACT_APP_API_URL}/friendrequest/${id}`
      );
      if (page === "profile") dispatch(getUserInfo(id));
      else dispatch(getAllFriendRequest());
      return dispatch({
        type: ACCEPT_FRIEND_REQUEST_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      return dispatch({ type: ACCEPT_FRIEND_REQUEST_ERROR, payload: err });
    }
  };

export const getAllFriendRequest =
  () => async (dispatch: any, getState: any) => {
    await dispatch({ type: GET_ALL_FRIEND_REQUEST });
    try {
      const response: any = await Axios.get(
        `${process.env.REACT_APP_API_URL}/friendrequest`
      );
      return dispatch({
        type: GET_ALL_FRIEND_REQUEST_SUCCESS,
        payload: response.data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_ALL_FRIEND_REQUEST_ERROR, payload: err });
    }
  };

export const getRecommendedUsers =
  (pageNumber = 1) =>
  async (dispatch: any) => {
    await dispatch({ type: GET_RECOMMENDED_USERS });
    try {
      const response: any = await Axios.get(
        `${process.env.REACT_APP_API_URL}/friend-recommendations?pageSize=10&pageNumber=${pageNumber}`
      );
      return dispatch({
        type: GET_RECOMMENDED_USERS_SUCCESS,
        payload: response.data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_RECOMMENDED_USERS_ERROR, payload: err });
    }
  };
