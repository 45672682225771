import {
    SELECT_AVATAR,
    SAVE_AVATAR
} from "../actions/avatar";
  
const avatarReducer = (state = {}, action: any) => {
    switch (action.type) {
        case SELECT_AVATAR:
            return {
                ...state,
                selected: action.payload
            };
        case SAVE_AVATAR:
            return {
                ...state,
                saved: action.payload
            }
        default:
            return state;
    }
};
  
export default avatarReducer;
