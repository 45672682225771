import { async } from "@firebase/util";
import axios from "axios";

export const GET_CHETS="GET_CHETS";
export const GET_CHETS_SUCESS="GET_CHETS_SUCESS";
export const GET_CHETS_FAIL="GET_CHETS_FAIL";
export const ADD_NEW_CHAT = "ADD_NEW_CHAT";
//
export const JOIN_ROOM = "JOIN_ROOM";
export const JOIN_ROOM_SUCESS = "JOIN_ROOM_SUCESS";
export const JOIN_ROOM_FAIL = "JOIN_ROOM_FAIL";
//
export const SAVE_WEB_SOCKET = "SAVE_WEB_SOCKET";
//
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_SUCESS = "SEND_MESSAGE_SUCESS";
export const SEND_MESSAGE_FAIL = "SEND_MESSAGE_FAIL";

export const SEND_TYPING = "SEND_TYPING";
export const RECIEVE_TYPING = "RECIEVE_TYPING";
export const RECIEVE_TYPING_FAIL = "RECIEVE_TYPING_FAIL";
export const RECIEVE_TYPING_SUCESS = "RECIEVE_TYPING_SUCESS";

//
export const GET_CHAT_MESSAGE = "GET_CHAT_MESSAGE";
export const GET_CHAT_MESSAGE_SUCESS = "GET_CHAT_MESSAGE_SUCESS";
export const GET_CHAT_MESSAGE_FAIL = "GET_CHAT_MESSAGE_FAIL";
//
export const NEW_MESSAGE_SOCKET = "NEW_MESSAGE_SOCKET";
//
export const REMOVE_ALL_CHATS= "REMOVE_ALL_CHATS";

//
export const OFFLINE_USERS = "OFFLINE_USERS";
export const OFFLINE_USERS_SUCCESS = "OFFLINE_USERS_SUCCESS";
export const OFFLINE_USERS_FAIL = "OFFLINE_USERS_FAIL";


//
export const ONLINE_USERS = "ONLINE_USERS";
export const ONLINE_USERS_SUCCESS = "ONLINE_USERS_SUCCESS";
export const ONLINE_USERS_FAIL = "ONLINE_USERS_FAIL";

//
export const LEAVE_ROOM = "LEAVE_ROOM";
export const LEAVE_ROOM_SUCCESS = "LEAVE_ROOM_SUCCESS";
export const LEAVE_ROOM_FAIL = "LEAVE_ROOM_FAIL";

//
export const ROOM_LEAVED = "ROOM_LEAVED";
export const ROOM_LEAVED_SUCCESS = "ROOM_LEAVED_SUCCESS";
export const ROOM_LEAVED_FAIL = "ROOM_LEAVED_FAIL";


export const getChats =
  (page = 0) =>
  async (dispatch: any, getState: any) => {
    const state = getState();
    await dispatch({ type: GET_CHETS });
    const url = `${process.env.REACT_APP_API_URL}/chat/get-list?offset=${page}`;
    try {
      const response: any = await axios.get(url);
      return dispatch({
        type: GET_CHETS_SUCESS,
        payload: response.data.data,
      });
    } catch (err) {
      return dispatch({ type: GET_CHETS_FAIL, payload: err });
    }
  };


  export const addNewChat = (data:any,history:any)=>
  async (dispatch: any, getState: any)=>{

    console.log('data == ', data);
    
    const token = localStorage.getItem("access_token");
    console.log("token",token)
    const config = {
      headers: {
         Authorization: `Bearer ${token}`,
    },  
    };
    const url = `${process.env.REACT_APP_API_URL}/chat/check-group/no/${data?.userId}`;
    try {
      const result =await axios.get(url,config);
      const maindata = result.data;

      console.log(maindata.data);
      console.log(data);
      if(maindata.data){
        dispatch({
          type:JOIN_ROOM,
          payload:maindata.data._id
        })        
      }else{
        dispatch({
          type:ADD_NEW_CHAT,
          payload:data
        })
      }
      history.push("/chats")
      
    } catch (error) {
      
    }

    //dispatch({type:ADD_NEW_CHAT,payload:data})
  }


export const joinRoom = (roomid:any)=>
  async (dispatch: any, getState: any)=>{
    let state = getState();
    console.log("this is socket",state.chat.socket)
    try {
      var joindata  = {RooomId:roomid};
      state.chat.socket.emit("joinedRoom",joindata);
      dispatch({type:JOIN_ROOM,payload:roomid});
      dispatch({type:JOIN_ROOM_SUCESS,payload:{
        roomid:roomid
      }})

      // listenTyping();
      // onlineUser();
      // offlineUser();
    } catch (error) {
      dispatch({type:JOIN_ROOM_FAIL,payload:error});
    }
  }


export const initsocket = ()=>
  async (dispatch: any, getState: any)=>{
    let state = getState();
    console.log(state.chat,'stat.chat')
    state.chat.socket.on("RoomJoined",(data)=>{
      console.log("RoomJoined",data)

      console.log(state.chat,'state.chatstate.chat')
    });
    state.chat.socket.on("AllMessageCallback",(data)=>{
      console.log("AllMessageCallback",data)
      dispatch({
        type: GET_CHETS_SUCESS,
        payload: data.chat,
      });
      dispatch({
        type:ADD_NEW_CHAT,
        payload:null
      })
    });
    state.chat.socket.on("MessageRecived",(data)=>{
      console.log("MessageRecived",data)
      if(data.messages[0].sender_id!==state.user?.data?.id){
        dispatch({
          type:NEW_MESSAGE_SOCKET,
          payload:data
        })
      }
    });
      
  }



  export const saveWebSocket = (socket:any)=>
  async (dispatch: any, getState: any)=>{
   dispatch({type:SAVE_WEB_SOCKET,
  payload:{
    socket:socket
  }
  })
  }



  export const removeallchats = ()=>
  async (dispatch: any, getState: any)=>{
   dispatch({type:REMOVE_ALL_CHATS,
  })
  }



  export const sendMessage = (data:any) =>

    async (dispatch: any, getState: any) => {

      console.log(process.env.REACT_APP_API_URL);
      let state = getState();

      dispatch({ type:SEND_MESSAGE, payload:data });

      const token = localStorage.getItem("access_token");
      
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",
          "type": "formData"
        },  
      };

      const url = `${process.env.REACT_APP_API_URL}/chat/send-message`;

      const formdata = new FormData();

      formdata.append('chat_id', data.chat_id);
      formdata.append("reciver_id", data.reciver_id);
      formdata.append("message", data.message);
      formdata.append("page_id", "");
      formdata.append("mime_type", data.mime_type);
      // formdata.append("attachments", data.attachments);
      if(data.attachments.length){
        data.attachments.map((e) => {
          formdata.append("attachments", e);
        })
      }
      
      console.log("form data",Array.from(formdata.entries()));
   
      try {
        const response: any = await axios.post(url, formdata, config);

        console.log("send message response : ", response);
        
        if(data.chat_id === ""){
          state.chat.socket.emit( "AllMessage", { "UserId": state.user?.data?._id } );
        }
        else{
          let sendData = response.data.data;
          sendData.RooomId = data.chat_id
          console.log(sendData)
          state.chat.socket.emit( "MessageSend", sendData );
          
          return dispatch({
            type: SEND_MESSAGE_SUCESS,
            payload:{ data: { messages:response.data.data.messages[0] },
            chatid:data.chat_id},
          });
        }
      } 
      catch (err) {
        console.log("eror",err)
        return dispatch({ type: SEND_MESSAGE_FAIL, payload: err });
      }
  }
  


  export const getMessagesForChat = (chatid,page = 0) =>
    async (dispatch: any, getState: any) => {
      const state = getState();
      await dispatch({ type: GET_CHAT_MESSAGE });
      const url = `${process.env.REACT_APP_API_URL}/chat/get-messages/${chatid}?offset=${page}}`;
      try {
        const response: any = await axios.get(url);
        var messages =  Array.from(response.data.data.message);
        dispatch({ type:RECIEVE_TYPING_FAIL, payload: null });
        return dispatch({
          type: GET_CHAT_MESSAGE_SUCESS,
          payload:{
            data: messages.reverse(),
            chatid:chatid
          },
        });
      } catch (err) {
        dispatch({ type:RECIEVE_TYPING_FAIL, payload: null });
        return dispatch({ type: GET_CHAT_MESSAGE_FAIL, payload: err });
      }
  };
 

  
  // typing events 


 export const messageTyping = (data:any) => 

    async (dispatch: any, getState: any) => {

      dispatch({ type:SEND_TYPING, payload:data });

      console.log(data);
      let state = getState();
      console.log("this is socket",state.chat.socket)

      try {
        var joindata  = { RooomId: data.roomid, UserId: data.userid };
        
        state.chat.socket.emit("TypeSend", joindata);
        console.log('reciever typing');
      } 
      catch (error) {
        console.log(error);
      }
    }
  

   export const listenTyping = () => 

    async (dispatch: any, getState: any) => {
      
      dispatch({ type:RECIEVE_TYPING, payload: null });

      let state = getState();
      console.log("this is socket",state.chat.socket)

      try {

        state.chat.socket.on("TypeRecived", (data) => {
          console.log('sender typing',data);
          dispatch({ type:RECIEVE_TYPING_SUCESS, payload: null });
        });

      } 
      catch (error) {
        console.log(error);
        dispatch({ type:RECIEVE_TYPING_FAIL, payload: null });
      }
    }


  // online / offline users events 

    export const offlineUser = () => 

    async (dispatch: any, getState: any) => {
      
      dispatch({ type:OFFLINE_USERS, payload: null });

      let state = getState();
      console.log("this is socket",state.chat.socket)

      try {

        state.chat.socket.on("UserOffline", (data) => {
          console.log('offline users',data);
          dispatch({ type:OFFLINE_USERS_SUCCESS, payload: data.data });
        });

      } 
      catch (error) {
        console.log(error);
        dispatch({ type:OFFLINE_USERS_FAIL, payload: null });
      }
    }


    export const onlineUser = () => 

    async (dispatch: any, getState: any) => {
      
      dispatch({ type:ONLINE_USERS, payload: null });

      let state = getState();
      console.log("this is socket",state.chat.socket)

      try {

        state.chat.socket.on("UserOnline", (data) => {
          console.log('ONLINE USERS',data);
          dispatch({ type:ONLINE_USERS_SUCCESS, payload: data.data });
        });

      } 
      catch (error) {
        console.log(error);
        dispatch({ type:ONLINE_USERS_FAIL, payload: null });
      }
    }
  
 // leave room events 

    export const LeaveRoom = () => 

    async (dispatch: any, getState: any) => {
      
      dispatch({ type:LEAVE_ROOM, payload: null });

      let state = getState();
      console.log("this is socket",state.chat.socket)

      try {

        state.chat.socket.emit("LeaveRoom", (data) => {
          console.log('Leave Room',data);
          dispatch({ type:LEAVE_ROOM_SUCCESS, payload: data.data });
        });

      } 
      catch (error) {
        console.log(error);
        dispatch({ type:LEAVE_ROOM_FAIL, payload: null });
      }
    }


    export const RoomLeaved = () => 

    async (dispatch: any, getState: any) => {
      
      dispatch({ type:ROOM_LEAVED, payload: null });

      let state = getState();
      console.log("this is socket",state.chat.socket)

      try {

        state.chat.socket.on("RoomLeaved", (data) => {
          console.log('Room Leaved',data);
          dispatch({ type:ROOM_LEAVED_SUCCESS, payload: data.data });
        });

      } 
      catch (error) {
        console.log(error);
        dispatch({ type:ROOM_LEAVED_FAIL, payload: null });
      }
    }
  

    export const deleteMessage = (chatid:any, messageid:any) =>

    async (dispatch: any, getState: any) => {

      console.log(process.env.REACT_APP_API_URL);
      let state = getState();

      const token = localStorage.getItem("access_token");
      
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Accept": "application/json"
        },  
      };

      const url = `${process.env.REACT_APP_API_URL}/chat/delete-chat-message/${chatid}/${messageid}`;
         
      try {
        const response: any = await axios.delete(url, config);

        console.log("delete message response : ", response);
      } 
      catch (err) {
        console.log("eror",err)
      }

    }
