import axios from 'axios';
import Swal from "sweetalert2";

export const UPDATE_PRIVACY_REQUEST = "UPDATE_PRIVACY_REQUEST";
export const UPDATE_PRIVACY_SUCCESS = "UPDATE_PRIVACY_SUCCESS";
export const UPDATE_PRIVACY_ERROR = "UPDATE_PRIVACY_ERROR";
export const UPDATE_PRIVACY_FIELD = "UPDATE_PRIVACY_FIELD";
export const UPDATE_PRIVACY_FIELD_SUCCESS = "UPDATE_PRIVACY_FIELD_SUCCESS";
export const UPDATE_PRIVACY_FIELD_ERROR = "UPDATE_PRIVACY_FIELD_ERROR";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS";
export const DELETE_ACCOUNT_ERROR = "DELETE_ACCOUNT_ERROR";
export const GET_SETTINGS_REQ = "GET_SETTINGS_REQ";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_ERROR = "GET_SETTINGS_ERROR";
export const privacySettings =
  (bool: any) => async (dispatch: any, getState: any) => {
    await dispatch({ type: UPDATE_PRIVACY_REQUEST, payload: bool });
    try {
      const response: any = await axios.put(
        `${process.env.REACT_APP_API_URL}/settings/privacy/${bool}`
      );
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Updated!", "", "success");
      return dispatch({
        type: UPDATE_PRIVACY_SUCCESS,
        payload: bool,
      });
    } catch (err) {
      return dispatch({ type: UPDATE_PRIVACY_ERROR, payload: err });
    }
  };

export const privacyFieldSettings =
  ({ fieldSetting, bool }: { fieldSetting: string; bool: boolean }) =>
  async (dispatch: any, getState: any) => {
    await dispatch({ type: UPDATE_PRIVACY_FIELD, payload: fieldSetting });
    try {
      const response: any = await axios.put(
        `${process.env.REACT_APP_API_URL}/profile/update-user-setting/${fieldSetting}/${bool}`
      );
     
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Updateddddd!", "", "warning");
     
      return dispatch({
        type: UPDATE_PRIVACY_FIELD_SUCCESS,
        payload: { data: response.data },
      });
    } catch (err) {
      return dispatch({ type: UPDATE_PRIVACY_FIELD_ERROR, payload: err });
    }
  };

export const deleteAccount = () => async (dispatch: any, getState: any) => {
  await dispatch({ type: DELETE_ACCOUNT });
  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_API_URL}/profile/delete`
    );
    localStorage.clear();
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire("Account Deleted!", "", "success");
    return dispatch({
      type: DELETE_ACCOUNT_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    return dispatch({ type: DELETE_ACCOUNT_ERROR, payload: err });
  }
};

export const deactivateAccount = () => async (dispatch: any, getState: any) => {
  await dispatch({ type: DELETE_ACCOUNT });
  try {
    const response: any = await axios.post(
      `${process.env.REACT_APP_API_URL}/profile/deactivate`
    );
    localStorage.clear();
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    }).fire("Account Deactivated!", "", "success");
    return dispatch({
      type: DELETE_ACCOUNT_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    return dispatch({ type: DELETE_ACCOUNT_ERROR, payload: err });
  }
};

export const FriendsPrivacy =
  (bool) => async (dispatch: any, getState: any) => {
    await dispatch({ type: UPDATE_PRIVACY_FIELD });
    try {
      const response: any = await axios.put(
        `${process.env.REACT_APP_API_URL}/profile/change-visibility-friends`,
        { status: bool }
      );
      Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }).fire("Updated!", "", "success");
      return dispatch({
        type: UPDATE_PRIVACY_FIELD_SUCCESS,
        payload: bool,
      });
    } catch (err) {
      return dispatch({ type: UPDATE_PRIVACY_FIELD_ERROR, payload: err });
    }
  };

export const getSettings = () => async (dispatch: any, getState: any) => {
  await dispatch({ type: GET_SETTINGS_REQ });
  try {
    const response: any = await axios.get(
      `${process.env.REACT_APP_API_URL}/settings/privacy/values`
    );
    return dispatch({
      type: GET_SETTINGS_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    return dispatch({ type: GET_SETTINGS_ERROR, payload: err });
  }
};
